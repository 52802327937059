import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading: boolean = false;
  usermessageService: any;
  deletechat: boolean =false;
  stopstreaming: boolean;

  private initialLoadingSubject = new BehaviorSubject<boolean>(true);
  public initialLoading$ = this.initialLoadingSubject.asObservable();

  setLoadingState(state: boolean) {
    this.isLoading = state;
  }
  setUserMessage(state: any) {
    this.usermessageService = state;
  }
  setdeleteConv(state: boolean) {
    this.deletechat = state;
  }
  setstopstreaming(state:boolean) {
    this.stopstreaming = state;
  }
  setInitialLoadingState(state: boolean) {
    this.initialLoadingSubject.next(state);
  }

}
