import { Component, OnInit, OnDestroy, Inject, ElementRef, Renderer2, AfterContentChecked, AfterViewInit, enableProdMode, ViewChild, HostListener } from '@angular/core';
import { CardOptions } from '@usitsdasdesign/dds-ng/cards';
import { WidthState, Themes, Size, ButtonKind, LabelPosition, ErrorState, TabThemeType } from '@usitsdasdesign/dds-ng/shared';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { SelectOptions, SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from './environments/environment';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { SharingmessageService } from './Services/sharingmessage.service';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { LanguageServiceService } from './Services/language-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { ChatService, UserAccessResponse } from './Services/chat.service';
import { TabsOptions, TabOptions, TabComponent } from '@usitsdasdesign/dds-ng/tabs';
import { WizardOptions, WizardThemes, WizardItemOptions, WizardItemComponent } from '@usitsdasdesign/dds-ng/wizard';
import { VerticalTabsOptions, VerticalTabOptions, VerticalTabComponent } from '@usitsdasdesign/dds-ng/vertical-tabs';
import { ErrorComponent } from './error/error.component';
import { LoadingService } from './Services/LoadingService';
import { AppComponentService } from './Services/appcomponentservice';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { PreviewPopupComponent } from './preview-popup/preview-popup.component';
import { ResetChatService } from './Services/reset-chat.service';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { ConHistoryService } from './Services/con-history.service';
import { HomeComponent } from './home/home.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { AgentsService } from './Services/agent.service';
import { BotResponseService } from './Services/bot-response.service';
import { error } from 'jquery';
import { ChatSettingsComponent } from './chat-settings/chat-settings.component';

// declare var bootstrap: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'Gen-D';
  private destroy = new Subject<void>();
  private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  //private privacy_URL: string = "https://mydeloitte.portal.deloitte.ca/sites/resources/ActiveFR/Internal/QualityandRiskManagement/Privacy/Documents/AI%20Guidelines%20Approved%20AI%20Systems_FR.pdf";
  private report_Bug: string = "https://deloittecan.service-now.com/mysupport?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9&topic=null";
  private globalClickListener: () => void;
  isSystemSettingsModalOpen: boolean = false;
  currentPath: string;
  text: string = "Token Limit: 13000\nSpeed: High\nReasoning: High\nTraining Upto: Oct 2023";
  selectedLanguage: string = 'EN';
  lblChat: string;
  lblChatHistory: string;
  lblRecent10Conversations: string;
  lblChatHistoryRename: string;
  lblChatHistoryDelete: string;
  lblSettings: string;
  lblUserCommunity: string;
  lblChatSettings: string;
  lblNewChat: string;
  lblNewTask: string;
  lblWhatsNew: string;
  verifyPreviewvalue: boolean = true;
  conversationsList: any = [];
  tooltipVisible: boolean = true;
  txtTooltipHeader: string = 'Usage Limit';
  txtTooltipBody: string = 'Your daily usage limit for the more advanced GPT-4o model is set to 50k tokens, which is roughly 40 pages (~300 words/page). The limit resets for your account every 24 hours. We suggest to use the 4o model only for complex reasoning and other advanced use cases.';
  convTooltipBody: string = 'jhihihi';
  hidemodelSelect: boolean = true;
  isShownconv: boolean;
  isEdit: boolean[];
  editIndex: number | null = null;
  showload: boolean = false;
  hisIsLoading: boolean = false;
  showNewButton: boolean;
  editedLabel1: any = false;
  isShown: boolean = false;
  chatConvId: any;
  dropdownIndex: number | null = null;
  selectedChatIndex: number | null = null;
  newchatclicked: boolean = true;
  convidstore: any;
  newchatcount: number = 0;
  isEditing: boolean = false;
  originalLabels: string[] = [];
  disableconversations: boolean;
  editedconvlabel: any;
  tooltipDisabled: boolean = false;
  privacy_URL: string;
  lblprivacy_URL: string;
  lblReportBug: string;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private https: HttpClient, private sharingService: SharingmessageService,
    private i18n: I18nService,
    private router: Router,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private elRef: ElementRef, private renderer: Renderer2,
    public loadingService: LoadingService,
    public appcomponentservice: AppComponentService,
    private modalService: ModalService, 
    private languageService: LanguageServiceService,
    private resetChatService: ResetChatService, private appComponentService: AppComponentService,
    private sharingmessageService: SharingmessageService, private conHistoryService: ConHistoryService,
    private agentService : AgentsService,private botResponseService : BotResponseService
  ) {
    // this.guid = uuidv4();
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   // Access the current path using ActivatedRoute
    //   this.currentPath = this.router.url; // Get the current URL
    // });
  }
  @ViewChild('sidebar') sidebar: ElementRef | undefined;
  @ViewChild('mainContent') mainContent: ElementRef | undefined;
  // @ViewChild('chatinput') chatInput: ElementRef | undefined;
  // @ViewChild('dynamicContent') dynamicContent: ElementRef | undefined;
  @ViewChild('pinIcon', { static: true }) pinIcon!: ElementRef;
  @ViewChild('unpinIcon', { static: true }) unpinIcon!: ElementRef;

  isPinned = true;
  systemModel: string;

  // get tooltipContent(): string {
  //   return `<style></style><div class="tooltip-header">${this.txtTooltipHeader}</div><div class="tooltip-body">${this.txtTooltipBody}</div>`;
  // }

  openWhatsNewModel() {
    if (this.appcomponentservice.isModelOpen) {
      this.modalService.close();
      this.appcomponentservice.setIsModelOpen(false);
    }
    this.appcomponentservice.setIsModelOpen(true);
    this.modalService.open(WhatsnewComponent, { lstoptionDislike: WhatsnewComponent }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
    });
  }

  serviceFuncSetIsPinned() {
    this.sharingmessageService.setIsPinned(this.isPinned);
  }


  showTooltip() {
    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  isSelected(path: string): boolean {
    //console.log(path, this.router.url)
    return this.router.url === path;
  }

  pinSidebar() {
    // Adjust CSS classes
    if (this.pinIcon != undefined) {
      this.renderer.removeClass(this.pinIcon.nativeElement, 'hide');
      this.renderer.removeClass(this.pinIcon.nativeElement, 'hidePin');
      this.renderer.addClass(this.unpinIcon.nativeElement, 'hideUnPin');

      // Set isPinned to true
      this.isPinned = true;

    }
  }

  togglePin() {
    setTimeout(() => {
      const pinIcon = document.getElementById('pin-icon');
      const unpinIcon = document.getElementById('unpin-icon');

      pinIcon?.classList.remove("hide");
      unpinIcon?.classList.remove("hideUnPin");
      pinIcon?.classList.add("hidePin");
      this.isPinned = !this.isPinned;

      if (this.sidebar?.nativeElement.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      } else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }, 10); // 10ms delay


  }
  toggleUnPin() {

    setTimeout(() => {
      const pinIcon = document.getElementById('pin-icon');
      const unpinIcon = document.getElementById('unpin-icon');

      pinIcon?.classList.remove("hide");
      pinIcon?.classList.remove("hidePin");
      unpinIcon?.classList.add("hideUnPin");
      this.isPinned = !this.isPinned;

      if (this.sidebar?.nativeElement.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      } else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }, 10); // 10ms delay
  }

  handleMouseLeave() {
    if (this.verifyPreviewvalue) {
      const chatInput = document.getElementById("chatinput")
      const dynamicContent = document.getElementById("dynamicContent")
      const chatMainContainer = document.getElementById("chatMainContainer");
      const sidebar = this.sidebar?.nativeElement;
      const mainContent = this.mainContent?.nativeElement;
      //const dynamicContent = this.dynamicContent?.nativeElement;
      //const chatInput = this.chatInput?.nativeElement;
      if (!this.isPinned) {
        sidebar.classList.add('hidden');
        sidebar.classList.remove('show');
        mainContent?.classList.add('main-content-expand');
        dynamicContent?.classList.add('dynamic-content-expand');
        chatInput?.classList.add('chat-input-expand');
        chatMainContainer?.classList.add('chat-main-container-expand');
      } else {
        sidebar.classList.add('show');
        sidebar.classList.remove('hidden');
        mainContent?.classList.remove('main-content-expand');
        dynamicContent?.classList.remove('dynamic-content-expand');
        chatInput?.classList.remove('chat-input-expand');
        chatMainContainer?.classList.remove('chat-main-container-expand');
      }
      if (this.sidebar?.nativeElement.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
    else return;
  }

  openUserCommunityLink(): void {
    window.open(this.teamsChannelUrl, '_blank');
  }
  openPrivacyPolicyink(): void {
    window.open(this.privacy_URL, '_blank');
  }
  openReportbugLink(): void {
    window.open(this.report_Bug, '_blank');
  }

  openSystemSettingsModal(event: Event) {
    if (this.appcomponentservice.isModelOpen) {
      this.modalService.close();
      this.appcomponentservice.setIsModelOpen(false);
    }

    if (this.isSystemSettingsModalOpen) {
      event.preventDefault();
      return;
    }

    if (this.isSelected('/document-analyzer') || this.isSelected('/efs') || this.isSelected('/deloitte-voice')) {
      event.preventDefault();
      return;
    }

    this.isSystemSettingsModalOpen = true;
    this.appcomponentservice.setIsModelOpen(true);
    let modalRef = this.modalService.open(
      ChatSettingsComponent, { isFooter: true, size: 'lg', isPinned: this.isPinned }).onClosed().subscribe(
        res => {
          this.appcomponentservice.setIsModelOpen(false);
          this.isSystemSettingsModalOpen = false;
        });
  }

  @HostListener('document:mousemove', ['$event'])
  //  @HostListener('document:click', ['$event'])
  //  onDocumentClick(event: MouseEvent) {
  //    const target = event.target as HTMLElement;
  //    if (!target.closest('.dropup')) {
  //      this.dropdownIndex = null;
  //    }
  //  }
  handleMainContentMouseLeave(event: MouseEvent) {
    if (this.isPinned) return;

    if (event.clientX <= 20) { // Adjust threshold if needed
      const chatInput = document.getElementById("chatinput")
      const dynamicContent = document.getElementById("dynamicContent")
      const chatMainContainer = document.getElementById("chatMainContainer");
      if (this.isPinned) {
        this.sidebar?.nativeElement.classList.add('hidden');
        this.sidebar?.nativeElement.classList.remove('show');
        this.mainContent?.nativeElement.classList.add('main-content-expand');
        dynamicContent?.classList.add('dynamic-content-expand');
        chatInput?.classList.add('chat-input-expand');
        chatMainContainer?.classList.add('chat-main-container-expand');

      } else {
        this.sidebar?.nativeElement.classList.add('show');
        this.sidebar?.nativeElement.classList.remove('hidden');
        this.mainContent?.nativeElement.classList.remove('main-content-expand');
        dynamicContent?.classList.remove('dynamic-content-expand');
        chatInput?.classList.remove('chat-input-expand');
        chatMainContainer?.classList.remove('chat-main-container-expand');

      }
      if (this.sidebar?.nativeElement.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }

    }

  }

  ngAfterViewInit() {
    // // Initialize Bootstrap tooltip
    // const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   return new (window as any).bootstrap.Tooltip(tooltipTriggerEl, {
    //     html: true
    //   });
    // });

    const sidebar = this.sidebar?.nativeElement;
    sidebar?.addEventListener('mouseleave', this.handleMouseLeave.bind(this));

    // const mainContent = this.mainContent?.nativeElement;
    // mainContent?.addEventListener('mouseleave', this.handleMainContentMouseLeave.bind(this));
  }
  ngAfterContentChecked(): void {

  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblChat = "Conversation";
            this.lblChatHistory = "Historique du chat"
            this.lblRecent10Conversations = "Seulement les dix dernières sessions sont disponibles ";
            this.lblChatHistoryRename = "Renommer"
            this.lblChatHistoryDelete = "Supprimer"
            this.lblSettings = "Liens utiles";
            this.lblUserCommunity = "Communauté d'utilisateurs";
            this.lblChatSettings = "Paramètres de Chat";
            this.lblNewChat = "Nouvelle conversation";
            this.lblWhatsNew = "Quoi de neuf ?";
            this.lblNewTask = "Nouvelle tâche";
            this.privacy_URL = "https://mydeloitte.portal.deloitte.ca/sites/resources/ActiveFR/Internal/QualityandRiskManagement/Privacy/Documents/AI%20Guidelines%20Approved%20AI%20Systems_FR.pdf";
            this.lblprivacy_URL = "Politique de Confidentialité";
            this.lblReportBug = "Signaler un bug";
            break;
          case "ES":
            this.lblChat = "Chat";
            this.lblChatHistory = "Historial de Chat";
            this.lblRecent10Conversations = "Solo las 10 sesiones más recientes estarán disponibles para revisar y reanudar.";
            this.lblChatHistoryRename = "Renombrar"
            this.lblChatHistoryDelete = "Eliminar"
            this.lblSettings = "Enlaces Útiles";
            this.lblUserCommunity = "Comunidad de Usuarios";
            this.lblChatSettings = "Configuración del Chat";
            this.lblNewChat = "Nuevo Chat";
            this.lblWhatsNew = "¿Qué Hay de Nuevo?";
            this.lblNewTask = "Nueva Tarea";
            this.privacy_URL = "https://mydeloitte.portal.deloitte.ca/sites/resources/ActiveEN/Internal/QualityandRiskManagement/Privacy/Documents/AI%20Guidelines%20Approved%20AI%20Systems_EN.pdf";
            this.lblprivacy_URL ="Política de Privacidad";
            this.lblReportBug = "Reportar Error";
            break;
          default:
            this.lblChat = "Chat";
            this.lblChatHistory = "Chat History";
            this.lblRecent10Conversations = "Only the most recent 10 sessions will be available for revisit and resume.";
            this.lblChatHistoryRename = "Rename"
            this.lblChatHistoryDelete = "Delete"
            this.lblSettings = "Useful Links";
            this.lblUserCommunity = "User Community";
            this.lblChatSettings = "Chat Settings";
            this.lblNewChat = "New Chat";
            this.lblWhatsNew = "What's New?";
            this.lblNewTask = "New Task";
            this.privacy_URL = "https://mydeloitte.portal.deloitte.ca/sites/resources/ActiveEN/Internal/QualityandRiskManagement/Privacy/Documents/AI%20Guidelines%20Approved%20AI%20Systems_EN.pdf";
            this.lblprivacy_URL ="Privacy Policy";
            this.lblReportBug = "Report Bug";
            break;
        }
        // alert(locale)
      });
  }

  ngOnInit(): void {
    this.verifyAllAccess();

    this.appcomponentservice.setIsModelOpen(true);

    this.serviceFuncSetIsPinned();
    this.verifyPreviewUSer();

    // this.pinSidebar();
    this.manageLocale();
    this.systemModel = "gpt4o"
    this.appcomponentservice.setSystemModel(this.systemModel);
    // this.appcomponentservice.setSystemSettings({ "systemMessage": "", "responseStyle": 0, "userQueryLength": "" });
    if (this.selectedLanguage == 'FR')
      this.https.get('assets/i18n/fr.json').subscribe((data: ITranslation) => this.i18n.setLocale('FR', data));
    else if (this.selectedLanguage == 'ES')
      this.https.get('assets/i18n/sp.json').subscribe((data: ITranslation) => this.i18n.setLocale('ES', data));
    
    else
      this.https.get('assets/i18n/en.json').subscribe((data: ITranslation) => this.i18n.setLocale('en', data));

  }

  private verifyAllAccess() {

    this.loadingService.setInitialLoadingState(true); // Show loading screen

    this.chatService.verifyAllAccess().subscribe({
      next: (response) => {
        // Update DVoice access
        console.log('Setting DVoice access:', response['dvoice']);
        this.appComponentService.setIsDVoiceUser(response['dvoice']);
        this.agentService.updateDeloitteVoiceAgent();
  
        // Update bot access
        this.appComponentService.setIsEFSSUser(response['efs']);
        this.appComponentService.setIsCRMUser(response['crm']);
        this.appComponentService.setIsBillingUser(response['billing']);
  
        // Update agent service states
        this.agentService.updateEFS();
        this.agentService.updateCrmBot();
        this.agentService.updateBillingBot();
  
        // Handle routing based on current path
        const path = window.location.pathname;
        if ((path === '/deloitte-voice' && !response['dvoice']) ||
            (path === '/efs' && !response['efs']) ||
            (path === '/crm' && !response['crm']) ||
            (path === '/billing' && !response['billing'])) {
          this.router.navigate(['/error']);
        }

        this.loadingService.setInitialLoadingState(false); // Hide loading screen after success

      },
      error: (error) => {
        console.error('Access verification failed:', error);
        this.router.navigate(['/error']);
      }
    });
  }

  verifyPreviewUSer() {
    this.verifyPreviewvalue = true;
    this.appcomponentservice.setIsModelOpen(false);
    this.pinSidebar();
    return;
    this.chatService.verifyPreviewUser()
      .subscribe((response: any) => {
        this.appcomponentservice.setIsModelOpen(false);
        if (response.result == "Success") {
          this.verifyPreviewvalue = true;
          // if (this.appcomponentservice.isModelOpen){
          //   this.modalService.close();
          //   this.appcomponentservice.setIsModelOpen(false);
          // }
          this.appcomponentservice.setIsModelOpen(false);
          this.pinSidebar();

          // this.modalService.open(PreviewPopupComponent).onClosed().subscribe(res => {
          //   this.appcomponentservice.setIsModelOpen(false);
          //    });
        }
        else {
          this.verifyPreviewvalue = false;
          this.appcomponentservice.setIsModelOpen(false);

        }
      }),
      (error: any) => {
        console.log("error", error);
      }
  }



  documentAnalyzerRedirect() {
    this.router.navigate(['/document-analyzer']);
    this.hidemodelSelect = false;
  }
  gendchatRedirect() {
    this.router.navigate(['/']);
    this.hidemodelSelect = true;

  }


  onLanguageChange(event: any) {
    // this.selectedLanguage = event.target.checked ? 'FR' : 'EN';
    // const selectElement = event.target as HTMLSelectElement;
    // this.selectedLanguage = selectElement.value;
    this.selectedLanguage = event.target.value;
    // You can use a service to share this value across components
    this.languageService.setCurrentLanguage(this.selectedLanguage);
    this.i18n.setLocale(this.selectedLanguage, EN);
    if (this.selectedLanguage == 'FR')
      this.https.get('assets/i18n/fr.json').subscribe((data: ITranslation) => this.i18n.setLocale('FR', data));
    else if (this.selectedLanguage == 'ES')
      this.https.get('assets/i18n/sp.json').subscribe((data: ITranslation) => this.i18n.setLocale('ES', data));
    
    else
      this.https.get('assets/i18n/en.json').subscribe((data: ITranslation) => this.i18n.setLocale('en', data));

    if (this.systemModel == "gpt4o") {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: High\nTraining Upto: Oct 2023" : this.selectedLanguage == "ES" ? this.text = "Límite de Tokens: 13000\nVelocidad: Alta\nRazonamiento: Alto\nEntrenamiento Hasta: Oct 2023" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Élevé\nFormation jusqu'à: Oct 2023";
    }
    else {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: Medium\nTraining Upto: Sep 2021" : this.selectedLanguage == "ES" ? this.text = "Límite de Tokens: 13000\nVelocidad: Alta\nRazonamiento: Medio\nEntrenamiento Hasta: Sep 2021" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Moyen\nFormation jusqu'à: Sep 2021";
    }
  }

  onClickSystemModel(event: any) {
    event.stopPropagation();
  }


  changeSystemModel() {
    console.log(this.systemModel)
    this.appcomponentservice.setSystemModel(this.systemModel);

    if (this.systemModel == "gpt4o") {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: High\nTraining Upto: Oct 2023" : this.selectedLanguage == "ES" ? this.text = "Límite de Tokens: 13000\nVelocidad: Alta\nRazonamiento: Alto\nEntrenamiento Hasta: Oct 2023" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Élevé\nFormation jusqu'à: Oct 2023";
    }
    else {
      this.selectedLanguage == "EN" ? this.text = "Token Limit: 13000\nSpeed: High\nReasoning: Medium\nTraining Upto: Sep 2021" : this.selectedLanguage == "ES" ? this.text = "Límite de Tokens: 13000\nVelocidad: Alta\nRazonamiento: Medio\nEntrenamiento Hasta: Sep 2021" : this.text = "Limite de jetons: 13000\nVitesse: Élevée\nRaisonnement: Moyen\nFormation jusqu'à: Sep 2021";
    }
  }

  newTask() {
    if(!this.appcomponentservice.isNewTaskDisabled){
    this.appcomponentservice.triggerNewTask(); 
    }
  }

  showNewTaskButton(): boolean{
    return this.router.url.startsWith('/deloitte-voice')
  }
  
  newChatchat() {
    
    this.appComponentService.setConversationId('');
    this.convidstore = null;
    if (this.isEditing && this.editIndex !== -1 && this.conversationsList[this.editIndex].label === "") {
      this.editIndex = null;
      this.isEditing = false;
      //this.getConvList();
    }
    this.newchatcount = this.newchatcount + 1;
    // Reset edit index and selected chat index
    this.editIndex = null;
    this.selectedChatIndex = null;
    this.isEditing = false;
    this.appcomponentservice.NewChatFunction();
    // Trigger the reset event
    this.newchatclicked = true;
    this.resetChatService.triggerReset();
    if (this.newchatcount > 0) {
      if (this.loadingService.usermessageService === "" || this.loadingService.usermessageService === undefined) {
        return;
      } else {
        if (this.loadingService.stopstreaming) {
          return;
        }
        //this.getConvList();
        this.loadingService.setUserMessage("");
      }
    }
  }
  newChat() {
    
    this.convidstore = null;
    if (this.isEditing && this.editIndex !== -1 && this.conversationsList[this.editIndex].label === "") {
      this.editIndex = null;
      this.isEditing = false;
      //this.getConvList();
    }
    
    this.newchatcount = this.newchatcount + 1;
    // Reset edit index and selected chat index
    this.editIndex = null;
    this.selectedChatIndex = null;
    this.isEditing = false;
    this.appcomponentservice.NewChatFunction();
    // Trigger the reset event
    this.newchatclicked = true;
    this.resetChatService.triggerReset();
    if (this.newchatcount > 0) {
      if (this.loadingService.usermessageService === "" || this.loadingService.usermessageService === undefined) {
        return;
      } else {
        if (this.loadingService.stopstreaming) {
          return;
        }
       // this.getConvList();
        this.loadingService.setUserMessage("");
      }
    }
  }
  newChatroute() {
    if (this.isEditing && this.editIndex !== -1 && this.conversationsList[this.editIndex].label === "") {
      this.editIndex = null;
      this.isEditing = false;
      //this.getConvList();
    }
    this.newchatcount = this.newchatcount + 1;
    // Reset edit index and selected chat index
    this.editIndex = null;
    this.selectedChatIndex = null;
    this.isEditing = false;
    this.appcomponentservice.NewChatFunction();
    // Trigger the reset event
    this.newchatclicked = true;
    this.resetChatService.triggerReset();
    if (this.newchatcount > 0) {
      if (this.loadingService.usermessageService === "" || this.loadingService.usermessageService === undefined) {
      } else {
        if (this.loadingService.stopstreaming) {
          return;
        }
        //this.getConvList();
        this.loadingService.setUserMessage("");
      }
    }

  }

  

  getConvList() { 
    this.agentService.updateEFS();
    this.conversationsList = [];
    this.isShownconv = true;
    this.conHistoryService.getconversationId()
      .subscribe(res => {
        this.isShownconv = false;
        this.isEdit = Array(this.conversationsList.length).fill(false);
        this.conversationsList = res.result;
        // this.conversationsList = Object.keys(res.result).map(day => ({
        //   day,
        //   items: res.result.days[day]
        // }));
      },
        (error) => {
          this.isShownconv = false;
          // this.appendMessage(this.botName, this.botImg, "left", this.fault, this.usermessage1, "error", " ");
        });
  }
  async chathistoryclick(convId: any, index: any) {
    //this.tooltipDisabled = true;
    // this.tooltipVisible = false;
    // this.hideTooltip();
    if (this.newchatclicked == true && this.loadingService.usermessageService) {
      //this.getConvList();
    }
    if (convId == this.convidstore) {
      return;
    } else {
      if (this.isEditing && this.editIndex !== -1 && this.conversationsList[this.editIndex].label === "") {
        return;
      }
      this.convidstore = convId;
      this.editIndex = -1;
      this.dropdownIndex = null;
      this.selectedChatIndex = convId;
      this.appComponentService.setConversationId(convId);
      this.newchatclicked = false;
    }
  }

  saveEditedlabel(convId: any, index: any, editedLabel: any): void {
    if (editedLabel === "") {
      return
    }
    this.isEdit[index] = false;
    this.editIndex = -1;

    if (this.showload) {
      this.conversationsList = [];
      this.hisIsLoading = true;
      this.showNewButton = true;
    }
    this.conHistoryService.RenameLabel(convId, editedLabel)
      .subscribe(res => {
        this.editedLabel1 = true;
        //this.getConvList();

      },
        (error) => {
          this.editedLabel1 = false;
          this.isShown = !this.isShown;
          this.hisIsLoading = false;
          this.showNewButton = false;
        });
  }
  startEdit(index: number, label: string) {
    this.originalLabels[index] = label;
    this.isEdit[index] = true;
    this.editIndex = index;
  }

  cancelEdit(index: number, originalLabel: any) {
    // this.conversationsList[index].label = this.originalLabels[index];
    this.isEdit[index] = false;
    this.editIndex = -1;
    // this.editedconvlabel = originalLabel; 
  }
  deleteConv(convId: any) {
    if (this.chatConvId == convId) {
      this.isShown = true;
    }
    if (this.showload) {
      this.conversationsList = [];
      this.hisIsLoading = true;
      this.showNewButton = true;
    }
    this.conHistoryService.deleteConvId(convId)
      .subscribe(res => {
        //this.getConvList();
        this.isShown = false;
        this.dropdownIndex = null; // Close the dropup menu
        this.removeGlobalClickListener(); // Ensure the global click listener is removed
        this.newChat();
      },
        (error) => {
          this.isShown = false;
          this.hisIsLoading = false;
          this.showNewButton = false;
        });
  }

  onRename(index: number, label: any): void {
    this.editedconvlabel = label;
    this.editIndex = index;
    this.dropdownIndex = null;
    this.isEditing = true;
  }
  toggleDropdown(index: number) {
    this.dropdownIndex = this.dropdownIndex === index ? null : index;
  }
  agentcentral() {
   
    this.loadingService.setLoadingState(false);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const dropdown = document.getElementById(`dropdown-${this.dropdownIndex}`);
    const target = event.target as HTMLElement;

    // Check if the clicked target is outside
    if (this.dropdownIndex !== null && dropdown && !dropdown.contains(target) && !target.classList.contains('sidebar-item-dots')) {
      this.dropdownIndex = null;
    }
  }

  private addGlobalClickListener() {
    this.globalClickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.dropup')) {
        this.dropdownIndex = null;
        this.removeGlobalClickListener();
      }
    });
  }

  private removeGlobalClickListener() {
    if (this.globalClickListener) {
      this.globalClickListener();
      this.globalClickListener = null;
    }
  }
  ngOnDestroy() {
    this.removeGlobalClickListener();
  }
  options: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'right-top',
    tooltipIndent: 35,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsInverse: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: 'tooltipclass',
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipShowDelay: 0,
    tooltipHideDelay: 0,
    tooltipTheme: Themes.dark
  };
}
