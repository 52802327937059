import { Component, Input, OnInit, ElementRef, HostListener } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { AppComponentService } from '../Services/appcomponentservice';
import { DropdownOption } from '../componenets/select-dropdown/select-dropdown.component';
import { FormBuilder } from '@angular/forms';
import { ChatSettings } from '../model/chat-settings.model';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings.component.html',
  styleUrls: ['./chat-settings.component.css']
})
export class ChatSettingsComponent implements OnInit {
  @Input() isFooter: boolean;
  @Input() size: string;
  readonly modalId = `chat-settings-${Math.random().toString(36).slice(2, 11)}`;  ariaLabelClose = 'Close settings';
  ariaLabelApply = 'Apply settings changes';
  ariaLabelReset = 'Reset settings to defaults';

  personalityOptions: DropdownOption[] = [
    {
        label: {
            en: "Straight to the Point",
            fr: "Droit au but",
            es: "Directo al Grano"
        },
        value: 0  // Deterministic
    },
    {
        label: {
            en: "Polished and Professional",
            fr: "Soigné et professionnel",
            es: "Pulido y Profesional"
        },
        value: 0.5  // Professional
    },
    {
        label: {
            en: "Creative and Fun",
            fr: "Créatif et amusant",
            es: "Creativo y Divertido"
        },
        value: 1  // Creative
    }
];

responseLengthOptions: DropdownOption[] = [
  {
      label: {
          en: "Concise",
          fr: "Concise",
          es: "Conciso"
      },
      value: 'Provide very short length and precise with your response'
  },
  {
      label: {
          en: "Balanced",
          fr: "Équilibré",
          es: "Equilibrado"
      },
      value: 'Provide medium length and balanced response'
  },
  {
      label: {
          en: "Elaborate",
          fr: "Détaillé",
          es: "Elaborado"
      },
      value: 'Provide very long length and elaborate with your response'
  }
];


    
  private destroy = new Subject<void>();
  // Flag to prevent immediate closing on modal open
  private modalJustOpened = false;
  
  lblChatSettings: string;
  btnClose: string;
  btnApply: string;
  settingsForm: any;
  systemPromptLabel: string;
  personalityLabel: string;
  responseLengthLabel: string;
  personalityHelperText: string;
  responseLengthHelperText: string;
  streamMessageLabel: string;
  streamMessageHelperText: string; 

constructor(
  private fb: FormBuilder,
  public modalService: ModalService,
  private i18n: I18nService,
  public appcomponentservice: AppComponentService,
  private elementRef: ElementRef
) {
  
   // Initialize form with default values from service
   this.settingsForm = this.fb.group({
    systemPrompt: [this.appcomponentservice.defaultChatSettings.systemPrompt],
    personality: [this.appcomponentservice.defaultChatSettings.personality],
    responseLength: [this.appcomponentservice.defaultChatSettings.responseLength],
    streamingEnabled: [this.appcomponentservice.defaultChatSettings.streamingEnabled]
  });
}
  

    @HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
  if (this.modalJustOpened) {
    return;
  }
  const chatSettingsElement = this.elementRef.nativeElement.querySelector('.chatsettings');
  const isInsideChatSettings = chatSettingsElement?.contains(event.target as Node);
  if (!isInsideChatSettings) {
    this.close();
  }
}
@HostListener('document:keydown.escape', ['$event'])
onKeydownHandler(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    this.close();
  }
}

// Add new method to handle Enter key
onKeyPress(event: KeyboardEvent, action: 'close' | 'apply' | 'reset'): void {
  if (event.key === 'Enter') {
    event.preventDefault();
    switch (action) {
      case 'close':
        this.close();
        break;
      case 'apply':
        this.onApply();
        break;
      case 'reset':
        this.onReset();
        break;
    }
  }
}
  
  close() {
    this.modalService.close();
  }


  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblChatSettings = "Paramètres de Chat";
            this.systemPromptLabel = "Instructions supplémentaires";
            this.personalityLabel = "Personnalité";
            this.responseLengthLabel = "Longueur";
            this.btnClose = "Fermer";
            this.btnApply = "Appliquer";
            this.personalityHelperText= "La personnalité vous permet de sélectionner le style général avec lequel le chatbot interagit avec vous.";
            this.responseLengthHelperText = "La longueur vous permet de contrôler le niveau de détail des réponses du chatbot.";
            this.streamMessageLabel = "Message en Flux";  
            this.streamMessageHelperText = "Recevez les réponses pendant leur génération. Désactivez pour recevoir uniquement les réponses complètes.";            break;
          case "ES":
            this.lblChatSettings = "Configuración del Chat";
            this.systemPromptLabel = "Instrucciones Adicionales";
            this.personalityLabel = "Personalidad";
            this.responseLengthLabel = "Longitud";
            this.btnClose = "Restablecer";
            this.btnApply = "Aplicar";
            this.personalityHelperText= "La personalidad te permite seleccionar el estilo general en el que el chatbot interactúa contigo.";
            this.responseLengthHelperText = "La longitud te permite controlar cuán detalladas son las respuestas del chatbot.";
            this.streamMessageLabel = "Mensaje en Tiempo Real"; 
            this.streamMessageHelperText = "Reciba las respuestas mientras se generan. Desactive para recibir solo respuestas completas.";
            break;
          default:
            this.lblChatSettings = "Chat Settings";
            this.systemPromptLabel = "Additional Instructions";
            this.personalityLabel = "Personality";
            this.responseLengthLabel = "Length";
            this.btnClose = "Reset";
            this.btnApply = "Apply";
            this.personalityHelperText= "Personality allows you to select the overall style in which the chatbot interacts with you.";
            this.responseLengthHelperText = "Length enables you to control how detailed the chatbot's responses are.";
            this.streamMessageLabel = "Stream Message";
            this.streamMessageHelperText = "Get replies as they are generated. Turn off to only get complete replies at once.";       
            break;
        }
      });
  }

  ngOnInit() {
    // Set flag to prevent immediate closing
    this.modalJustOpened = true;
    
    // Reset flag after a short delay
    setTimeout(() => {
      this.modalJustOpened = false;
    }, 300);

    this.centerModal();

    // Load current settings
    const currentSettings = this.appcomponentservice.getChatSettings();
    this.settingsForm.patchValue(currentSettings);

    // Subscribe to settings changes
    this.appcomponentservice.chatSettings$
    .pipe(takeUntil(this.destroy))
    .subscribe(settings => {
      this.settingsForm.patchValue(settings, { emitEvent: false });
    });
    
    this.manageLocale();
  }
  
  onApply() {
    if (this.settingsForm.valid) {
      const settings: ChatSettings = {
        systemPrompt: this.settingsForm.get('systemPrompt').value,
        personality: this.settingsForm.get('personality').value,
        responseLength: this.settingsForm.get('responseLength').value,
        streamingEnabled: this.settingsForm.get('streamingEnabled').value
      };
      
      this.appcomponentservice.updateChatSettings(settings);
      this.modalService.close();
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

 
onReset() {
  this.settingsForm.patchValue(this.appcomponentservice.defaultChatSettings);
}

centerModal() {
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
}
  

}