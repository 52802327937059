import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent {
  @Input() show: boolean = false;
  @Input() logoText: string = 'Gen-D';
  @Input() loadingText: string = 'Initializing...';
}