import { Component } from '@angular/core';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { ChatService } from '../Services/chat.service';
import { AppComponentService } from '../Services/appcomponentservice';
import { BotResponseService } from '../Services/bot-response.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {
  private destroy = new Subject<void>();
  constructor(private i18n: I18nService, private chatService: ChatService,
     private appComponentService: AppComponentService, private botResponseService: BotResponseService){}

  lblError: string;
  lblAccess: string;

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblError = "Erreur";
            this.lblAccess = "Vous n'avez pas accès à cette page";
            break;
          case "ES":
            this.lblError = "Error";
            this.lblAccess = "No tienes acceso a esta página";
            break;
          default:
            this.lblError = "Error";
            this.lblAccess = "You don't have access to this page";
            break;

        }
        // alert(locale)
      });
  }

  ngOnInit() {
    // Use shared state instead of making API call
    this.chatService.getAccessState().subscribe(access => {
        if (access) {
            this.appComponentService.setIsEFSSUser(access['efs']);
            this.appComponentService.setIsCRMUser(access['crm']);
            this.appComponentService.setIsBillingUser(access['billing']);
        }
    });
    this.manageLocale();
  }

}
