<!-- Loading state -->
<app-loading-screen 
   [show]="loadingService.initialLoading$ | async"
   logoText="Gen-D"
   loadingText="Loading...">
</app-loading-screen> 

<!-- Main application -->
<div class="containerMenu" *ngIf="verifyPreviewUSer" >
   <div class="sidebar" id="sidebar" #sidebar>
      <div class="sidebar-main-container">
         <div class="sidebar-header-container">
            <div class="sidebar-header">
               <div class="GenDLogo" (click)="newChatchat()" [routerLink]="loadingService.stopstreaming ? null: ['/']" 
               [ngClass]="{'disabled-link': loadingService.stopstreaming}">
                  <span> <img class="GenDLogoImage" src="../assets/images/logo_v2.png" /></span><span
                     class="GenDHeading">Gen-D</span>
               </div>
               <span #pinIcon class="pin-icon pinned hide" id="pin-icon" (click)="togglePin()">
               <!-- <i class="fa fa-thumb-tack" aria-hidden="true"></i> -->
               <img src="../assets/images/unpin.png" />
               </span>
               <span #unpinIcon class="pin-icon" id="unpin-icon" (click)="toggleUnPin()">
               <img src="../assets/images/pin.png" />
               </span>
            </div>
            <div class="sidebar-section">
               <div class="sidebarModel"  (click)="newChatchat()"
                    [routerLink]="loadingService.stopstreaming ? null : ['/']" 
                    [ngClass]="{'active': isSelected('/'), 'disabled-link': loadingService.stopstreaming, 
                    'disabled-link-efs': appComponentService.isEFSUser === false && path === '/efs'}">
               <div style="display: flex; align-items: center;margin-left: -3px;">
                  <img class="table-image" [src]="isSelected('/') ? '../assets/images/chat-messages-active.png' : '../assets/images/chat-messages.png'" />
                  <span class="sidebarChat">{{lblChat}}</span>
               </div>
               <div class="tooltip-wrapper" [hidden]="!isSelected('/')">
                  <select (click)="onClickSystemModel($event)" [ddsTooltip]="text" [tooltipInvokeType]="options.tooltipInvokeType"
                  [tooltipType]="options.tooltipType" [tooltipSize]="options.tooltipSize"
                  [tooltipPosition]="options.tooltipPosition" [tooltipIndent]="options.tooltipIndent"
                  [tooltipHasBeak]="options.tooltipHasBeak" 
                  [tooltipIsOutsideClick]="options.tooltipIsOutsideClick" [tooltipIsDynamic]="options.tooltipIsDynamic"
                  [tooltipCustomClass]="options.tooltipCustomClass" class="modeldropdown" [(ngModel)]="systemModel"
                  (change)="changeSystemModel()" (mouseover)="showTooltip()" (mouseout)="hideTooltip()">
                  <option value="gpt4o">GPT 4o</option>
                  <option value="gpt3.5">GPT 3.5</option>
                  </select>
               </div>
            </div>
         </div>
      </div>
      <div class="sidebar-body-container">
         <div class="sidebar-section agent-central-section">
            <br />
            <div class="sidebarSectionPart5"
            (click)="!loadingService.stopstreaming && newChatchat()" 
            [routerLink]="loadingService.stopstreaming ? null : ['/agent-central']"
            [ngClass]="{'active': isSelected('/agent-central'), 'disabled-link': loadingService.stopstreaming}">
         <div class="sidebarSectionPart6">
            <img class="sidebarDocAnalyzerImage" src="../assets/images/AgentCentral.png" />
            </div>
            <div class="sidebarDocumentAnalyzer">
            Agent Central
            </div>
         </div>
      </div>
      <hr />
      <app-recent-agents (agentClicked)="newChatchat()"></app-recent-agents>
   <!-- <hr /> -->
   <div class="sidebar-section chat-settings-section">
      <!-- <div class="sidebar-header">{{lblChatHistory}}</div> -->
      <!-- <div class="sidebar-items">
         <div *ngFor="let c of conversationsList; let i = index" 
         class="sidebar-item history-item"  [routerLink]="loadingService.stopstreaming ? null : ['/']"
          (click)="!loadingService.stopstreaming && chathistoryclick(c.convId, i)" 
         [class.active]="(editIndex !== i && selectedChatIndex === c.convId) && isSelected('/') "
         [ngClass]="{'disabled-link': loadingService.stopstreaming}"
         (mouseover)="showTooltip()" (mouseout)="hideTooltip()">
         
        <span 
         *ngIf="editIndex !== i" 
         class="sidebar-item-label"
         [ddsTooltip]="c.label.length > 100 ? (c.label | slice:0:100) : c.label"
         [tooltipInvokeType]="options.tooltipInvokeType"
         [tooltipType]="options.tooltipType"
         [tooltipSize]="options.tooltipSize"
         [tooltipPosition]="options.tooltipPosition"
         [tooltipIndent]="options.tooltipIndent"
         [tooltipHasBeak]="options.tooltipHasBeak"
         [tooltipIsDisabled]="loadingService.stopstreaming"
         [tooltipIsInverse]="options.tooltipIsInverse"
         [tooltipIsOutsideClick]="options.tooltipIsOutsideClick"
         [tooltipIsDynamic]="options.tooltipIsDynamic"
         [tooltipTheme]="options.tooltipTheme"
         [tooltipShowDelay]="options.tooltipShowDelay"
         [tooltipHideDelay]="options.tooltipHideDelay"
         [tooltipCustomClass]="options.tooltipCustomClass" >{{ c.label }}</span>
         <div *ngIf="editIndex === i" class="input-container">
            <input
               type="text"
               (keyup.enter)="saveEditedlabel(c.convId, i, editedconvlabel)"
               class="rename_field"
               [(ngModel)]="editedconvlabel">
            <img class="icon-save" src="../assets/images/check.png" (click)="saveEditedlabel(c.convId, i, editedconvlabel)" />
            <img class="icon-cancel" src="./assets/images/cancel.png" (click)="cancelEdit(i,c.label)" />
         </div>
         <img *ngIf="(editIndex !== i && selectedChatIndex === c.convId) && isSelected('/')" 
         class="sidebar-item-dots" 
         src="../assets/images/three-dots.svg"
         (click)="toggleDropdown(i)" />
         <div *ngIf="dropdownIndex === i" class="dropdown-menu" id="dropdown-{{i}}">
            <div class="dropdown-option" (click)="onRename(i,c.label)">{{lblChatHistoryRename}}</div>
            <hr class="dropdown-divider"/>
            <div class="dropdown-option" (click)="deleteConv(c.convId)">{{lblChatHistoryDelete}}</div>
         </div>
      </div>
      <div class="sidebar-item">
         <span class="sidebar-item-label chat-info-label">{{lblRecent10Conversations}}</span>
      </div> 
   </div>-->
</div>
   <!-- <div class="sidebar-section chat-settings-section">
      <div class="sidebar-header">{{lblChatHistory}}</div>
     
   <div class="row sidebar-item-label-conv" 
      [ngClass]="{'disabled-div': disableConv, 'highlighted-row': selectedChatIndex === i}" 
      *ngFor="let c of conversationsList; let i = index"
      style="margin-top:3px;margin-bottom:3px">
            <div [class.highlightedbutton]="selectedChatIndex === i"
               style="display:flex;flex-direction: row;justify-content:space-between;width:120%" id="conv-history">
            <div class="prompt-text sidebar-item-conv">
               <button class="history_button"
                        class="sidebarSectionPart5" 
                        [routerLink]="['/']" 
                        (click)="chathistoryclick(c.convId, i)" id="btn-history{{i}}">
                  <div class="sidebar-item-label-conv" *ngIf="!(editIndex === i)" style="font-weight: 300; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px;">{{c.label}}</div>
               </button>
               <input *ngIf="editIndex === i"
                     type="text"
                     (keyup.enter)="saveEditedlabel(c.convId, i, c.label)"
                     class="rename_field"
                     [(ngModel)]="c.label">
            </div>
            <div class="dropup">
               <button class="btn" type="button" (click)="toggleDropdown(i)">
                  <img *ngIf="selectedChatIndex === i && !(editIndex === i)" src="../assets/images/dots.jpg"
                     style="width:15px;height:15px;margin-top: 0.7em">
                  <div class="edit-icons-container" *ngIf="editIndex === i">
                  <img alt="ok" type="button" src="../assets/images/tick_history.svg"
                        class="edit-icon" (click)="saveEditedlabel(c.convId, i, c.label)">
                  <img alt="cancel" type="button" src="../assets/images/cancel.png"
                        class="edit-icon" (click)="cancelEdit(i)">
                  </div>
               </button>
         
               <div *ngIf="dropdownIndex === i" class="dropdown-menu dropdown-menu-left"
                     style="width: 6em !important;max-width: 6em !important;min-width: 6em !important;margin-left: 1em;">
                  <button class="dropdown-item" title="Edit" (click)="onRename(i)">Rename</button>
                  <button class="dropdown-item" title="Delete" (click)="deleteConv(c.convId)">Delete</button>
               </div>
            </div>
            </div>
         </div>
       </div>
       <div class="sidebarSectionPart14">
         <div class="sidebarChatHistoryMessage">
           {{lblRecent10Conversations}}</div>
         </div>  -->
         <hr />
         <div class="sidebar-section settings-section">
      <div class="sidebarSettingsLabel">
         {{lblSettings}}
      </div>
      <div class="sidebarSettings userCommunity" (click)="openUserCommunityLink()">
         {{lblUserCommunity}}
      </div>
      <div class="sidebarSettings  privacypolicy"   (click)="openReportbugLink()">
         {{lblReportBug}}    
      </div>
      <div class="sidebarSettings  privacypolicy"    href="{{privacy_URL}}" (click)="openPrivacyPolicyink()">
         {{lblprivacy_URL}}
      </div>
   </div>
</div>
</div>
</div>
<div class="main-content"  #mainContent id="main-content"  
[ngClass]="{'modelPopUpEnables':appcomponentservice.isModelOpen}">
<div id="subMainContent" [ngClass]="{ 'isLoaderActivated': loadingService.isLoading }">
   <div class="header-container">
      <div class="header-group">
         <div class="header-item logo-item" [routerLink]="['/']" (click)="newChatchat()">
            <span> <img class="GenDLogoImage-3" src="../assets/images/logo_v2.png" /></span> &nbsp; <span
               class="GenDHeading-3">Gen-D</span>
         </div>
         <div class="header-item item2" *ngIf="appcomponentservice.isNewChatVisible">
            <button [disabled]="appcomponentservice.isSendButtonDisabled" 
            [ngClass]="{'disabled-link-efs': appComponentService.isEFSUser === false && isSelected('/error')}"
             class="new-task-button" (click)="newChatchat()" >{{lblNewChat}} </button>
         </div>
         <div class="header-item item2" *ngIf="showNewTaskButton()">
            <button [disabled]="appcomponentservice.isNewTaskDisabled" class="new-task-button" (click)="newTask()" >{{lblNewTask}} </button>
         </div>
         <div class="" *ngIf="isSelected('/')">
            <button id="chatSettings" (click)="openSystemSettingsModal($event)"  class="chatsettingsclass" 
              [ngClass]="{'disabled-link': isSelected('/document-analyzer') || isSelected('/efs') || isSelected('/deloitte-voice')}"
              [attr.disabled]="isSelected('/document-analyzer') || isSelected('/efs') || isSelected('/deloitte-voice') ? true : null">
              <img style="width: 18px;height: 18px;margin-bottom: 3px;"
               src="../assets/images/settings.png" /> {{lblChatSettings}}</button>
         </div>
      </div>
      <div class="header-group">
         <div class="header-item">
            <button class="btn btnWhatsNewStyle" (click)="openWhatsNewModel()"><img src="../assets/images/whatsnewicon.png" /><span
               class="txtWhatsNewStyle"> {{lblWhatsNew}}</span></button>
         </div>
         <div class="header-item">
            <select id="lang" [value]="selectedLanguage" (change)="onLanguageChange($event)">
            <option>EN</option>
            <option>FR</option>
            <option>ES</option>
            </select>
         </div>
      </div>
   </div>
   <router-outlet />
</div>
</div>
</div>
<div  *ngIf="!verifyPreviewvalue" >
   <app-error></app-error>
</div>
