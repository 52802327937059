export const environment = {
    production: true,
    APIBaseUrl:"https://gendapi.deloitte.ca/",
    clientId:"f89cf4ee-b0e9-41e2-ad1e-709b09da6713",
    tenentId:"36da45f1-dd2c-4d1f-af13-5abe46b99921",
    APIScopeURL:"api://7ca624f5-8232-49fd-b747-25fcd3fe01ca/user_impersonation",
    feedbackURL: "https://gendapi.deloitte.ca/OpenAPI/SubmitFeedBack",
    docfeedbackURL : "https://gendapi.deloitte.ca/api/DocumentAnalyzer/SubmitFeedBack",
    EFSFeedbackURL: "https://gendapi.deloitte.ca/OpenAPI/SubmitEFSFeedBack",
    uploadDocumentURL : "https://gendapi.deloitte.ca/api/Blob/UploadDocument",
    processDocumentURL : "https://gendapi.deloitte.ca/api/DocumentAnalyzer/",
    refreshDocumentURL : "https://gendapi.deloitte.ca/api/Blob/RefreshBlobs",
    summaryDocumentURL : "https://gendapi.deloitte.ca/api/DocumentAnalyzer/DocumentAnalyzerResults",
    orangeProjectURL : "https://gendapi.deloitte.ca/api/Onboarding/OnboardingQuery",
    orangefeedbackURL : "https://gendapi.deloitte.ca/api/Onboarding/SubmitOnboardingFeedBack",
    verifyUserURL : "https://gendapi.deloitte.ca/api/Onboarding/VerifyUser",
    //verifyEFSUserURL : "https://gendbetaapi.deloitte.ca/OpenAPI/VerifyEFSUser",
    downloadDocumentURL : "https://gendapi.deloitte.ca/api/Onboarding/DownloadDocument",
    previewGenDURL : "https://gend.deloitte.ca/",
    efsURL: "https://gendapi.deloitte.ca/",
    DMSKBURL: "https://genai.deloitte.ca/km",
    defaultMsg1_En:"Of course! I'd be happy to help you find information on any topic you need. Please provide me with the specific topic you would like information on, and I will do my best to assist you.",
    defaultMsg1_Fr:"Of course! I'd be happy to help you find information on any topic you need. Please provide me with the specific topic you would like information on, and I will do my best to assist you.",
    defaultMsg2_En:"Certainly! I'd be happy to help you find information on your desired topic. Please provide me with the specific topic you would like information on, and I'll do my best to assist you.",
    defaultMsg2_Fr:"Certainly! I'd be happy to help you find information on your desired topic. Please provide me with the specific topic you would like information on, and I'll do my best to assist you.",
    defaultMsg3_En:"Of course! I'd be happy to help you find information on any topic you need. Please provide me with the specific topic you would like information on, and I will do my best to assist you.",
    defaultMsg3_Fr:"Of course! I'd be happy to help you find information on any topic you need. Please provide me with the specific topic you would like information on, and I will do my best to assist you."
  
 };