import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpParams} from '@angular/common/http';
import { protectedResources } from '../../auth-config';
 import {HttpHeaders} from '@angular/common/http';
 import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
 import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { environment } from '../environments/environment';
import { MsalService } from '@azure/msal-angular';
 const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private accessSubject = new BehaviorSubject<AllAccessResponse | null>(null);
  public access$ = this.accessSubject.asObservable();

  gptModel: any;
  userQuery: any; 
  switch_value: boolean = false;
  private chatGptUrl: string = 'https://botacsapidev-openapipoc.azcactniprdase01.appserviceenvironment.net/OpenAPI/OpenAPIResults?userQuery=';
  constructor(private httpClient: HttpClient,private https: HttpClient,  private http: HttpClient,private msalService: MsalService) { }
  apiurl = protectedResources.apiDBot.endpoint;
  summaryApiUrl = protectedResources.searchSummaryDocument.endpoint;
  orangeApiUrl = protectedResources.orange.endpoint;
  verifyApiUrl = protectedResources.verifyuser.endpoint;  
  //efsURL=protectedResources.efs.endpoint
  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.profile
  ];
  toggle_button(){
   this.switch_value = !this.switch_value;
   if(this.switch_value == true){
    this.gptModel = "gpt4o";
   } 
   else{
    this.gptModel = "gpt3.5";  
   } 
  }
  toggle_analyzer(){
    this.switch_value = false;
  }
  toggle_value(){
    return this.switch_value; 
  }
  getSearchResult(search:any,Model:any,temp:any,topP:any,fpenalty:any,ppenalty:any,sysmessage:any,convid:any): Observable<any> { 
    const headers = new HttpHeaders();
    headers.set('access-control-allow-origin',["*"]);
    headers.set('content-type','application/json');
    headers.set('Accept', 'application/json;charset=UTF-8');
    headers.set('responseType','text');
    headers.set('Authorization','Bearer');
    const params = new HttpParams();
    params.append('responseType','text');
    params.append('observe','response');
    const options ={
      headers : 'headers',
      params : 'params'
    }
  return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"convId":convid,"gptModel":Model,"temperature":temp,"topP":topP,"frequencyPenalty":fpenalty,"presencePenalty":ppenalty,"systemMessage":sysmessage});  
}
  getMarkdownContent(search: any, Model: any, temp: any, topP: any, fpenalty: any, ppenalty: any, sysmessage: any, convid: any, regenerateFlag:any): Observable<string> {
    const headers = new HttpHeaders();
    headers.set('access-control-allow-origin',["*"]);
    headers.set('content-type','application/json');
    headers.set('Accept', 'application/json;charset=UTF-8');
    headers.set('responseType','text');
    headers.set('Authorization','Bearer');
    const params = new HttpParams();
    params.append('responseType','text');
    params.append('observe','response');
    const options ={
      headers : 'headers',
      params : 'params'
    }
  return this.httpClient.post<string>(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"convId":convid,"gptModel":Model,"temperature":temp,"topP":topP,"frequencyPenalty":fpenalty,"presencePenalty":ppenalty,"regenerateFlag":regenerateFlag,"systemMessage":sysmessage}
  ).pipe(
    map(response => response)
  );
  }
  async getStreaming1(
    search: any,
    Model: any,
    temp: any,
    topP: any,
    fpenalty: any,
    ppenalty: any,
    sysmessage: any,
    convid: any,
    regenerateFlag: any
  ): Promise<ReadableStreamDefaultReader<any>> {
    var url=this.apiurl+"OpenAPI/OpenAPIResultsStreaming";
    return new Promise<ReadableStreamDefaultReader<any>>((resolve, reject) => {
      const account = this.msalService.instance.getAllAccounts()[0];
      this.msalService
        .acquireTokenSilent({
          account: account,
          scopes: [...protectedResources.apiDBot.scopes.write],
        })
        .toPromise()
        .then((token) => {
          fetch(url, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token?.accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userQuery: search,
              convId: convid,
              gptModel: Model,
              temperature: temp,
              topP: topP,
              frequencyPenalty: fpenalty,
              presencePenalty: ppenalty,
              regenerateFlag: regenerateFlag,
              systemMessage: sysmessage,
            }),
          })
            .then((response) => {
              if (response.ok) {
                if (response.body === null) {
                  reject(new Error('Response body is null'));
                } else {
                  resolve(response.body.getReader()); // Return the reader
                }
              } else {
                reject(new Error('Not possible in current scenario'));
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  async getStreaming(search: any, Model: any, temp: any, topP: any, fpenalty: any, ppenalty: any, sysmessage: any, convid: any, regenerateFlag:any): Promise<ReadableStream>{
    return new Promise<ReadableStream>((resolve, reject) => {
    const account = this.msalService.instance.getAllAccounts()[0];
    this.msalService.acquireTokenSilent({
      account: account,
      scopes: [...protectedResources.apiDBot.scopes.write]
    }).toPromise().then(token => {
      fetch(this.apiurl + 'OpenAPI/OpenAPIResultsStreaming', {
        method : 'POST',
        headers: {
          'Authorization': `Bearer ${token?.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userQuery: search,
          convId: convid,
          gptModel: Model,
          temperature: temp,
          topP: topP,
          frequencyPenalty: fpenalty,
          presencePenalty: ppenalty,
          regenerateFlag: regenerateFlag,
          systemMessage: sysmessage,
      })
    }).then(response => {
     if(response.ok){
      if (response.body === null) {
        reject(new Error('Response body is null'));
      } else {
        resolve(response.body);
        console.log(response);
      }
     }
     else{
      reject(new Error('Not possible in current scenario'));
     }
    }).catch(error => {
      reject(error);
    });
    }).catch(error => {
      reject(error);
    });
    });
  }
  getSearchRes(search:any,Model:any,sysmessage:any,temp:any,convid:any, regenerateFlag:any, responseLength:any): Observable<any> { 
    const headers = new HttpHeaders();
    headers.set('access-control-allow-origin',["*"]);
    headers.set('content-type','application/json');
    headers.set('Accept', 'application/json;charset=UTF-8');
    headers.set('responseType','text');
    headers.set('Authorization','Bearer');
    const params = new HttpParams();
    params.append('responseType','text');
    params.append('observe','response');
    const options ={
      headers : 'headers',
      params : 'params'
    }
    console.log("response", this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"convId":convid,"gptModel":Model,"temperature":temp,"systemMessage":sysmessage, "regenerateFlag":regenerateFlag, "responseLength":responseLength})
  return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"convId":convid,"gptModel":Model,"temperature":temp,"systemMessage":sysmessage, "regenerateFlag":regenerateFlag, "responseLength":responseLength});  

 // return this.httpClient.post(this.apiurl+"OpenAPI/OpenAPIResults",{"userQuery":search,"convId":convid,"gptModel":Model,"temperature":temp,"topP":topP,"frequencyPenalty":fpenalty,"presencePenalty":ppenalty,"regenerateFlag":regenerateFlag,"systemMessage":sysmessage});  
}
  getOrangeResult(search:any): Observable<any>{
    let headers3 = new HttpHeaders({
      'content-Type' : 'application/json',  
      'access-control-allow-origin': ["*"],
    });
    return this.httpClient.post(this.orangeApiUrl,{"prompt":search},{headers: headers3});   
  }
  getDocumentResult(search:any): Observable<any> { 
    let headers2 = new HttpHeaders({
      'content-Type': 'application/json',
      'access-control-allow-origin': ["*"],
    });
          
    const body = {
      prompt: search
      
    };
   return this.http.post(
      this.summaryApiUrl, {
        prompt: search
        
      } , {headers : headers2})
}

  //   getEFSData(search: any): Observable<any> {
// var URL=this.getEFSData
//     return this.http.get(
//       this.efsURL + search, {
//     })
//   }
  
getEFSData(search: any): Observable<any> {
  let headers2 = new HttpHeaders({
    'content-Type': 'application/json',
    'access-control-allow-origin': ["*"],
  });

  const body = {
    prompt: search

  };
  return this.http.post(
    this.apiurl+"/OpenAPI/GetEFSData", {
    prompt: search

  }, { headers: headers2 })
}

  verifyPreviewUser():Observable<any>{
    let headers3 = new HttpHeaders({
      'content-Type': 'application/json',
      'access-control-allow-origin': ["*"],
    });
    return this.httpClient.post(this.apiurl + "OpenAPI/VerifyPreviewUser",{},{headers : headers3});
  }
  downloadDocument(fileName: string) {
    return this.httpClient.get(this.apiurl + "api/Onboarding/DownloadDocument?fileName="+fileName, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    })
  }

  verifyAllAccess(): Observable<AllAccessResponse> {
    // If we already have access data, return it
    if (this.accessSubject.value) {
      return of(this.accessSubject.value);
    }

    // Otherwise make the API call
    return this.httpClient.get<AllAccessResponse>(
      `${this.apiurl}OpenAPI/VerifyAllAccess`
    ).pipe(
      map(response => {
        this.accessSubject.next(response);
        return response;
      }),
      catchError(error => {
        console.error('VerifyAllAccess error:', error);
        const defaultResponse = { 
          dvoice: false, 
          efs: false, 
          crm: false, 
          billing: false 
        };
        this.accessSubject.next(defaultResponse);
        return of(defaultResponse);
      })
    );
  }

  getAccessState(): Observable<AllAccessResponse | null> {
    return this.access$;
  }

}

export interface UserAccessResponse {
  hasAccess: boolean;
}

export interface AllAccessResponse {
  [key: string]: boolean;  // Will handle all access types in one flat structure
}