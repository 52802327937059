import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { map, Observable } from 'rxjs';
import { protectedResources } from 'src/auth-config';

@Injectable({
  providedIn: 'root'
})
export class BotResponseService {
  constructor(private httpClient: HttpClient,private https: HttpClient,  private http: HttpClient,private msalService: MsalService) { }
  apiurl = protectedResources.apiDBot.endpoint;

 
  getEFSData(search: any): Observable<any> {
    let headers2 = new HttpHeaders({
      'content-Type': 'application/json',
      'access-control-allow-origin': ["*"],
    });

    const body = {
      prompt: search

    };
    return this.http.post(
      this.apiurl+"api/Bot/GetEfsData", {
      prompt: search

    }, { headers: headers2 })
  }

  getBillingData(search: any): Observable<any> {
    let headers2 = new HttpHeaders({
      'content-Type': 'application/json',
      'access-control-allow-origin': ["*"],
    });

    const body = {
      prompt: search

    };
    return this.http.post(
      this.apiurl+"api/Bot/GetBillingData", {
      prompt: search

    }, { headers: headers2 })
  }

  getCrmData(search: any): Observable<any> {
    let headers2 = new HttpHeaders({
      'content-Type': 'application/json',
      'access-control-allow-origin': ["*"],
    });

    const body = {
      prompt: search

    };
    return this.http.post(
      this.apiurl+"api/Bot/GetCrmData", {
      prompt: search

    }, { headers: headers2 })
  }

  getKBChecks(botname):Observable<any>{
    return this.httpClient.post(this.apiurl + "api/Bot/GetKBChecks",{BotAppName : botname});
  }

  BotDownloadDocument(fileName: string, botAppName: string): Observable<Blob> {
    const url = `${this.apiurl}api/Bot/BotDownloadDocument`;
    const encodedFileName = encodeURIComponent(fileName);
  console.log(" document url",url)
    const body = {
      file_name: encodedFileName,
      botAppName: botAppName
    };
  
    return this.httpClient.post(url, body, {
      responseType: 'blob',
      headers: {
        'content-Type': 'application/json',
      'access-control-allow-origin': ["*"], // Assuming you have the auth token stored
      }
    });
  }

  // BotPreviewDocument(fileSource: Blob): Observable<Blob> {
  //   return this.http.get(`${this.apiurl}Bot/BotDownloadDocument?file_name=${fileSource}`, {
  //     responseType: 'blob'
  //   });
  // }

  BotPreviewDocument(fileSource: Blob, botAppName: string): Observable<Blob> {
    const url = `${this.apiurl}api/Bot/BotDownloadDocument`;
    const body = {
      file_name: fileSource,
      botAppName: botAppName
    };
  
    return this.http.post(url, body, {
      responseType: 'blob',
      headers: {
        'content-Type': 'application/json',
      'access-control-allow-origin': ["*"], // Assuming you have the auth token stored
      }
    });
  }

}
