import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { AccordionModule } from '@usitsdasdesign/dds-ng/accordion';
import { DatepickerModule } from '@usitsdasdesign/dds-ng/datepicker';
// import ExpandableRowModule to be able to use ddsExpandableRow directive
import { ExpandableRowModule } from '@usitsdasdesign/dds-ng/shared/expandable-row';
// import FilterModule to be able to use filter component
import { FilterModule } from '@usitsdasdesign/dds-ng/filter';
// import SortByModule to be able to use sort pipe
import { SortByModule } from '@usitsdasdesign/dds-ng/shared/sort-by';
// import HighlightModule to be able to use highlight pipe
// import FocusHandlerModule to be able to use focus directive

import { VerticalTabsModule } from '@usitsdasdesign/dds-ng/vertical-tabs';
import { FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import { InputModule } from '@usitsdasdesign/dds-ng/input';
import { SelectModule } from '@usitsdasdesign/dds-ng/select';
import { CardModule } from '@usitsdasdesign/dds-ng/cards';
import { ProfileModule } from '@usitsdasdesign/dds-ng/profile';
import { HeaderModule } from '@usitsdasdesign/dds-ng/header';
import { ModalModule } from '@usitsdasdesign/dds-ng/modal';
import { ResizeHandlerModule } from '@usitsdasdesign/dds-ng/shared/resize-handler';
import { UploaderModule } from '@usitsdasdesign/dds-ng/uploader';
import { CheckboxModule } from '@usitsdasdesign/dds-ng/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TabsModule } from '@usitsdasdesign/dds-ng/tabs';
import { TextareaModule } from '@usitsdasdesign/dds-ng/textarea';
import { TooltipModule } from '@usitsdasdesign/dds-ng/tooltip';
import { ChatService } from './Services/chat.service';
import { SharingmessageService } from './Services/sharingmessage.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { I18nModule } from '@usitsdasdesign/dds-ng/shared/i18n';
import { WizardModule } from '@usitsdasdesign/dds-ng/wizard';
import { LogLevel, IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from '../auth-config';
import { ClipboardModule } from 'ngx-clipboard';
import { DragdropfileDirective } from './Directives/dragdropfile.directive';
import { ErrorComponent } from './error/error.component';
import { ToastModule } from '@usitsdasdesign/dds-ng/toast';
import { DropdownModule } from '@usitsdasdesign/dds-ng/dropdown';
import { FeedbackLikeComponent } from './feedback-like/feedback-like.component';
import { FeedbackDisLikeComponent } from './feedback-dis-like/feedback-dis-like.component';
import { MarkdownModule } from 'ngx-markdown';
import { HomeComponent } from './home/home.component';
import { DocumentAnalyzerComponent } from './document-analyzer/document-analyzer.component';
import { TagsModule } from '@usitsdasdesign/dds-ng/tags';
import { PreviewPopupComponent } from './preview-popup/preview-popup.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { AgentCentralComponent } from './agent-central/agent-central.component';
import { RecentAgentsComponent } from "./recent-agents/recent-agents.component";
import { EfsComponent } from './efs/efs.component';
import { DeloitteVoiceComponent } from './deloitte-voice/deloitte-voice.component';
import { DeloitteVoiceFlowComponent } from './deloitte-voice/deloitte-voice-flow/deloitte-voice-flow.component';
import { DeloitteVoiceReviseComponent } from './deloitte-voice/deloitte-voice-revise/deloitte-voice-revise.component';
import { SelectDropdownComponent } from './componenets/select-dropdown/select-dropdown.component';
import { FileDownloadCardComponent } from './componenets/file-download-card/file-download-card.component';
import { DeloitteVoiceProcessingComponent } from './deloitte-voice/componenets/deloitte-voice-processing/deloitte-voice-processing.component';
import { DeloitteVoiceCompletedComponent } from './deloitte-voice/componenets/deloitte-voice-completed/deloitte-voice-completed.component';
import { DeloitteVoiceErrorComponent } from './deloitte-voice/componenets/deloitte-voice-error/deloitte-voice-error.component';
import { FileUploadComponent } from './componenets/file-upload/file-upload.component';
import { FileDisplayComponent } from './componenets/file-display/file-display.component';
import { DeloitteVoiceCreateComponent } from './deloitte-voice/deloitte-voice-create/deloitte-voice-create.component';
import { ChatWindowComponent } from "./componenets/chat-window/chat-window.component";
import { BillingComponent } from './billing/billing.component';
import { CrmComponent } from './crm/crm.component';
import { FilePreviewPopupComponent } from './file-preview-popup/file-preview-popup.component';
import { ChatSettingsComponent } from './chat-settings/chat-settings.component';
import { ToggleSwitchComponent } from './componenets/toggle-switch/toggle-switch.component';
import { WhatsNewService } from './Services/whatsnew.service';
import { LoadingScreenComponent } from './componenets/loading-screen/loading-screen.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log("message", message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(protectedResources.apiDBot.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiDBot.scopes.write]
    },
  ]);
  protectedResourceMap.set(protectedResources.searchSummaryDocument.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.searchSummaryDocument.scopes.write]
    }
  ]);
  protectedResourceMap.set(protectedResources.orange.endpoint,[
    {
      httpMethod: 'POST', 
      scopes: [...protectedResources.orange.scopes.write]
    }
  ]);
  protectedResourceMap.set(protectedResources.verifyuser.endpoint,[
    {
      httpMethod: 'POST', 
      scopes: [...protectedResources.verifyuser.scopes.write]
    }
  ]);
  protectedResourceMap.set(protectedResources.uploadDocument.endpoint,[
    {
      httpMethod: 'POST', 
      scopes: [...protectedResources.uploadDocument.scopes.write]   
    }
  ]);
  protectedResourceMap.set(protectedResources.processUploadedDocument.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.processUploadedDocument.scopes.read]
    }
  ]);
  protectedResourceMap.set(protectedResources.resetUploadedDocument.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.resetUploadedDocument.scopes.read]
    }
  ]);
  protectedResourceMap.set(protectedResources.conversationHistory.endpoint, [
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.conversationHistory.scopes.write]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.conversationHistory.scopes.write]
    }
  ]);
  return {
    interactionType:InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DocumentAnalyzerComponent,
    DragdropfileDirective,
    ErrorComponent ,
    FeedbackLikeComponent,
    FeedbackDisLikeComponent,
    HomeComponent,
    PreviewPopupComponent,
    WhatsnewComponent,
    AgentCentralComponent,
    RecentAgentsComponent,
    EfsComponent,
    RecentAgentsComponent,
    DeloitteVoiceComponent,
    DeloitteVoiceFlowComponent,
    DeloitteVoiceCreateComponent,
    DeloitteVoiceReviseComponent,
    DeloitteVoiceProcessingComponent,
    DeloitteVoiceErrorComponent,
    DeloitteVoiceCompletedComponent,
    SelectDropdownComponent,
    FileDownloadCardComponent,
    FileDisplayComponent,
    FileUploadComponent,
    BillingComponent,
    ChatWindowComponent,
    CrmComponent,
    LoadingScreenComponent, 
    FilePreviewPopupComponent,
    ChatSettingsComponent,
    ToggleSwitchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CheckboxModule,
    AccordionModule,
    ButtonModule,
    ExpandableRowModule,
    FilterModule,
    SortByModule,
    FocusHandlerModule,
    DatepickerModule,
    InputModule,
    SelectModule,
    CardModule,
    ProfileModule,
    HeaderModule,
    TooltipModule,
    ModalModule,
    ResizeHandlerModule,
    ReactiveFormsModule,
    FormsModule,
    TabsModule,
    TextareaModule,
    UploaderModule,
    HttpClientModule,
    MsalModule,
    ClipboardModule,
    I18nModule,
    WizardModule,
    VerticalTabsModule,
    ToastModule,
    DropdownModule,
    MarkdownModule.forRoot(),
    TagsModule,
],

  
  providers: [ChatService, SharingmessageService,AppComponent, WhatsNewService,
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent,MsalRedirectComponent], 
  })
export class AppModule { }