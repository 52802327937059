import { Component, OnInit } from '@angular/core';
import { AppComponentService } from '../Services/appcomponentservice';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { Router } from '@angular/router';
import { ChatService, AllAccessResponse } from '../Services/chat.service';

@Component({
  selector: 'app-deloitte-voice',
  templateUrl: './deloitte-voice.component.html',
  styleUrl: './deloitte-voice.component.css'
})
export class DeloitteVoiceComponent implements OnInit {
  constructor(
    private recentAgentsService: RecentAgentsService,
    public appComponentService: AppComponentService, 
    private chatService: ChatService,
    private router: Router
  ) {}
  
  ngOnInit(): void {
    this.appComponentService.enableNewTaskButton();
    this.appComponentService.setIsNewChatVisible(false);
    this.checkAccess();
  }

  private checkAccess() {
    this.chatService.getAccessState().subscribe(access => {
      if (access && !access['dvoice']) {
        this.router.navigate(['/error']);
      } else if (access) {
        const agentPath = "/deloitte-voice";
        this.recentAgentsService.addRecentPath(agentPath);
      }
    });
  }
}