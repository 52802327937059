<div class="chatsettings" 
     [ngClass]="{'middleModelPopup': appcomponentservice.isPinChecked}"
     role="dialog"
     [attr.id]="modalId"
     aria-labelledby="settings-title"
     aria-modal="true">
  <div class="dds-modal-overlay" (click)="close()" aria-hidden="true"></div>

  <div ddsResize 
       [ngClass]="{'dds-modal_lg': size === 'lg'}" 
       class="dds-modal modal-container">
    <form [formGroup]="settingsForm" 
          (ngSubmit)="onApply()" 
          class="dds-modal__body modal-body"
          role="form">
      <div class="model-header">
        <h2 class="modal-title" id="settings-title">{{lblChatSettings}}</h2>
        <button type="button"
                class="cross-container" 
                (click)="close()"
                (keydown)="onKeyPress($event, 'close')"
                [attr.aria-label]="ariaLabelClose">
          <img src="../../assets/images/cross.svg" 
               class="cross-image" 
               alt=""
               aria-hidden="true">
        </button>
      </div>

      <div class="additional-information-section">
        <label for="system-prompt" class="setting-label">{{systemPromptLabel}}</label>
        <textarea id="system-prompt"
                 class="settings-textarea"
                 formControlName="systemPrompt"
                 [attr.aria-label]="systemPromptLabel">
        </textarea>
      </div>

      <div class="other-control-section" role="group" aria-labelledby="personality-label">
        <div class="other-control-column-1">
          <h3 id="personality-label" class="setting-label">{{personalityLabel}}</h3>
          <p id="personality-help" class="setting-helper-text">{{personalityHelperText}}</p>
        </div>  
        <div class="other-control-column-2">
          <app-select-dropdown
            formControlName="personality"
            [options]="personalityOptions"
            [placeholder]="'Select personality'"
            aria-describedby="personality-help">
          </app-select-dropdown>
        </div>  
      </div>

      <div class="other-control-section" role="group" aria-labelledby="length-label">
        <div class="other-control-column-1">
          <h3 id="length-label" class="setting-label">{{responseLengthLabel}}</h3>
          <p id="length-help" class="setting-helper-text">{{responseLengthHelperText}}</p>
        </div>  
        <div class="other-control-column-2">
          <app-select-dropdown
            formControlName="responseLength"
            [options]="responseLengthOptions"
            [placeholder]="'Select length'"
            aria-describedby="length-help">
          </app-select-dropdown>
        </div>  
      </div>

      <div class="other-control-section" role="group" aria-labelledby="stream-label">
        <div class="other-control-column-1">
          <h3 id="stream-label" class="setting-label">{{streamMessageLabel}}</h3>
          <p id="stream-help" class="setting-helper-text">{{streamMessageHelperText}}</p>
        </div>  
        <div class="other-control-column-2">
          <app-toggle-switch
            formControlName="streamingEnabled"
            [ariaLabel]="streamMessageLabel"
            aria-describedby="stream-help">
          </app-toggle-switch>
        </div>
      </div>

      <div class="model-footer">
        <button type="button" 
                class="reset-button" 
                (click)="onReset()"
                (keydown)="onKeyPress($event, 'reset')"
                [attr.aria-label]="ariaLabelReset">
          {{btnClose}}
        </button>
        <button type="submit" 
                class="start-button"
                (keydown)="onKeyPress($event, 'apply')"
                [attr.aria-label]="ariaLabelApply">
          {{btnApply}}
        </button>
      </div>
    </form>
  </div>
</div>