<div class="toggle-container">
  <button
    type="button"
    role="switch"
    class="toggle-switch"
    [class.checked]="isChecked"
    [class.initialized]="isInitialized"
    [class.disabled]="disabled"
    [attr.aria-checked]="isChecked"
    [attr.aria-label]="ariaLabel"
    [attr.aria-disabled]="disabled"
    [disabled]="disabled"
    (click)="toggleSwitch()"
    (keydown)="toggleSwitch($event)"
    tabindex="0">
    <div class="toggle-circle"></div>
  </button>
</div>