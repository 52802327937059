import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
 
@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleSwitchComponent,
      multi: true
    }
  ]
})
export class ToggleSwitchComponent implements ControlValueAccessor, OnInit {
  @Input() isChecked: boolean = false;
  @Input() ariaLabel: string = 'Toggle switch'; // Add aria-label input
  isInitialized: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};
 
  ngOnInit() {
    this.isChecked = this.isChecked || false;
    requestAnimationFrame(() => {
      this.isInitialized = true;
    });
  }

  toggleSwitch(event?: KeyboardEvent) {
    if (event) {
      // Handle keyboard events
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        this.toggle();
      }
    } else {
      this.toggle();
    }
  }

  private toggle() {
    if (!this.disabled) {
      this.isChecked = !this.isChecked;
      this.onChange(this.isChecked);
      this.onTouched();
    }
  }

  // Add disabled state handling
  private _disabled = false;
  
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  writeValue(value: boolean): void {
    this.isChecked = value;
  }
 
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
 
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}