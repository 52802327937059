import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Agent } from '../model/agent.model';
import { Router } from '@angular/router';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { AgentsService } from '../Services/agent.service';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../Services/LoadingService';
import { AppComponentService } from '../Services/appcomponentservice';
import { ChatService } from '../Services/chat.service';
import { BotResponseService } from '../Services/bot-response.service';

@Component({
  selector: 'app-recent-agents',
  templateUrl: './recent-agents.component.html',
  styleUrls: ['./recent-agents.component.css']
})
export class RecentAgentsComponent implements OnInit {
  recentPaths$ = this.recentAgentsService.recentPaths$;
  private agents: Agent[] = [];
  private destroy = new Subject<void>(); 
  language: string = 'en';
  lblRecentlyUsedAgents: string;
  @Output() agentClicked = new EventEmitter<void>();

  constructor(
    private recentAgentsService: RecentAgentsService,
    private agentsService: AgentsService,
    private router: Router,
    private i18n: I18nService,  
    public loadingService: LoadingService,
    public appcomponentservice: AppComponentService,
    private appComponentService: AppComponentService,
    private chatservice:ChatService, private botResponseService: BotResponseService
  ) {}


  ngOnInit() {
    this.agentsService.getAgents().subscribe(agents => {
      this.agents = agents;
    });
    this.manageLocale();

      // Use shared state instead of making API call
      this.chatservice.getAccessState().subscribe(access => {
        if (access) {
            this.appComponentService.setIsEFSSUser(access['efs']);
            this.appComponentService.setIsCRMUser(access['crm']);
            this.appComponentService.setIsBillingUser(access['billing']);
        }
    });
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.language = 'fr'
            this.lblRecentlyUsedAgents = "Agents utilisés récemment";
            break;
          case "ES":
          this.language = 'es'
          this.lblRecentlyUsedAgents = "Agentes Recientemente Usados";
          break;
          default:
            this.language = 'en'
            this.lblRecentlyUsedAgents = "Recently Used Agents";
            break;
        }
      });
  }   

  navigateToPath(path: string): void {
    if (this.loadingService.stopstreaming) {
      return; // Exit the method if streaming is stopped
    }
  
    const agent = this.getAgent(path);
  
    if (!agent) {
      return; // Exit if no agent is found
    }
  
    if (this.isPathActive(agent.path)) {
      this.agentClicked.emit();
    } else if (!agent.disabled) {
      this.router.navigate([path]);
    }
  }

  isPathActive(path: string): boolean {
    var exactMatch =  this.router.url === path;
    return path ? this.router.url.startsWith(`${path}/`) || exactMatch : exactMatch;
  }
 

  getAgent(path: string): Agent | undefined {
    return this.agents.find(agent => agent.path === path);
  }

}
