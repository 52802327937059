import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, concatMap, delay, of, takeUntil } from 'rxjs';
import { ChatService } from '../Services/chat.service';
import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from '../Services/LoadingService';
import { AppComponentService } from '../Services/appcomponentservice';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DocumentUploadService } from '../Services/document-upload.service';
import { FeedbackDisLikeComponent } from '../feedback-dis-like/feedback-dis-like.component';
import { FeedbackLikeComponent } from '../feedback-like/feedback-like.component';
import { HttpClient } from '@angular/common/http';
import { Themes, Size, WidthState, ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { ResetBlobStorageService } from '../Services/reset-blob-storage.service';
import { ResetChatService } from '../Services/reset-chat.service';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ChatTextHeightServiceService } from '../Services/chat-text-height-service.service';
import { FilePreviewPopupComponent } from '../file-preview-popup/file-preview-popup.component';
import * as moment from 'moment';
import { BotResponseService } from '../Services/bot-response.service';
import { marked } from 'marked';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
interface ChatMessage {
  user: string;
  text: string;
  references?: any[];
}
@Component({
  selector: 'app-billing',
  standalone: false,
  templateUrl: './billing.component.html',
  styleUrl: './billing.component.css'
})
export class BillingComponent {
  private resetSubscription: Subscription;
  cancelButtonKind = ButtonKind.secondaryLoud;
  primaryButtonTheme = Themes.green;
  primaryButtonKind = ButtonKind.primaryLoud;

  private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  private destroy = new Subject<void>();
  lblNameGreeting: string;
  lblHelpGreeting: string;
  lblHiName: string;
  lblGenDIntro1: string;
  lblGenDIntro2: string;
  lblEFS_01: string;
  KB_URL: string;
  lblAskQuestion: string;
  lblAskFirstQues: string;
  userName: any;
  feedbacklike: any;
  userQuery: string;
  isCopy: boolean;
  userResponse: string;
  isStreaming: boolean;
  errorCount: any;
  errorMesg: any;
  references: any;
  docerrorMessage: boolean = false;
  ErrorMessage: string;
  loadmsgprc: any;
  loadmsgEmb: any;
  loadmsgSumry: any;
  resetDisabled: boolean = true;
  displayName: any;
  lblSorrySomething2: string;
  lblSorrySomething1: string;
  lblUserCommunity: string;
  botName: string = "billing";
  // verifyEFSvalue: boolean = false;
  copyIndex: any;
  typesToUpload: string;
  lblReferences: string;

  constructor(private modalService: ModalService, private chatService: ChatService, public loadingService: LoadingService,
    public appcomponentservice: AppComponentService, private render: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef,
    private https: HttpClient, private DocumentUploadService: DocumentUploadService, private i18n: I18nService,
    private ResetBlobStorageService: ResetBlobStorageService, private resetChatService: ResetChatService, private recentAgentsService: RecentAgentsService, private router: Router,
    private chatservice: ChatService, private chatTextHeightServiceService: ChatTextHeightServiceService, private botResponseService: BotResponseService) {

  }
  private subscriptions: Subscription[] = [];
  isLoading = false;
  disableSendButton = false;
  isDefaultText: boolean;
  userPrompt: string;
  systemModel = "GPT 3.5"
  systemMessage: string;
  responseStyle: string;
  guid = uuidv4()
  userId: string;
  regenerateFlag = false;
  userQueryLength;
  likedIndex: number = -1;
  dislikedIndex: number = -1;
  isLiked: boolean = false;
  isDisLiked: boolean = false;
  likedIndices: Set<number> = new Set();
  dislikedIndices: Set<number> = new Set();
  disabledLikeIndices = new Set<number>();
  disabledDisLikeIndices = new Set<number>();
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('dynamicTextarea') dynamicTextarea!: ElementRef;
  textareaRef: any;
  messages = new BehaviorSubject<ChatMessage[]>([]);
  messages$ = this.messages.asObservable();
  private defaultHeight: number = 50.5;
  private sendMessageSubscription: Subscription;
  autoScrollEnabled: boolean = true;
  lastScrollTop: number = 0;
  showScrollToBottom: boolean = false;
  lblIndexLastRefreshDate: string;

  onScroll(event: Event): void {
    const container = this.chatContainer.nativeElement;
    const currentScrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (currentScrollTop < this.lastScrollTop) {
      this.autoScrollEnabled = false;
    }
    const atBottom = scrollHeight - clientHeight <= currentScrollTop + 15;
    this.showScrollToBottom = !atBottom;

    if (atBottom) {
      this.autoScrollEnabled = true;
    }
    this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For negative scrolling
  }

  OnScrollToBottom() {
    setTimeout(() => {
      this.autoScrollEnabled = true;
    }, 590);
    this.scrollToBottomSlow();
  }

  scrollToBottomSlow() {
    const container = this.chatContainer.nativeElement;
    const target = container.scrollHeight; // Total scrollable height
    const start = container.scrollTop; // Current scroll position
    const distance = target - start; // Distance to scroll
    const duration = 600; // Duration of the scroll in milliseconds
    let startTime: number | null = null;
    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime; // Initialize start time
      const timeElapsed = currentTime - startTime; // Time elapsed since start
      const progress = Math.min(timeElapsed / duration, 1);
      const ease = easeInOutQuad(progress);
      container.scrollTop = start + distance * ease; // Update scroll position

      if (timeElapsed < duration) {
        requestAnimationFrame(animation); // Continue the animation
      } else {
        this.showScrollToBottom = false; // Hide the button after scrolling
      }
    };

    requestAnimationFrame(animation);
    function easeInOutQuad(t: number) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
  }
  openFeedbackDislikeModel(index, botRes) {
    this.appcomponentservice.setIsModelOpen(true);
    this.isDisLiked = true;
    this.dislikedIndex = index;
    this.likedIndex = -1;
    this.dislikedIndices.add(index);
    this.likedIndices.delete(index);
    this.disabledDisLikeIndices.add(index);
    var FeedbackDisLike = {
      userid: this.userId,
      name: this.userName,
      apiResponse: botRes,
      userQuery: this.userQuery,
      botAppName: "billing",
      isBotActive: true

    };
    this.modalService.open(FeedbackDisLikeComponent, { lstoptionDislike: FeedbackDisLike, }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
    });
  }

  doLiked(index: number): boolean {
    return this.likedIndices.has(index);
  }
  doDisliked(index: number): boolean {
    return this.dislikedIndices.has(index);
  }

  openFeedbackLikeModel(index: number, botRes: string) {
    this.appcomponentservice.setIsModelOpen(true);
    this.isLiked = true;
    this.likedIndex = index;
    this.dislikedIndex = -1;
    this.likedIndices.add(index);
    this.dislikedIndices.delete(index);
    this.disabledLikeIndices.add(index);
    var FeedbackLike = {
      userid: this.userId,
      name: this.userName,
      apiResponse: botRes,
      userQuery: this.userQuery,
      botAppName: "billing",
      isBotActive: true

    };

    this.modalService.open(FeedbackLikeComponent, { lstlikeOptions: FeedbackLike }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
    });
  }
  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        this.getKBChecks();
        switch (locale) {
          case "FR":
            this.lblNameGreeting = "Bonjour";
            this.lblHelpGreeting = "Je suis Agent Bill, votre expert en matière de facturation. Comment puis-je vous aider aujourd'hui?";
            this.lblGenDIntro1 = "FR Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
            this.lblGenDIntro2 = "Conteneur de données mis à jour: ";
            this.lblEFS_01 = "2 janvier 2025"; this.KB_URL = environment.DMSKBURL; //"https://genai.deloitte.ca/km";
            this.lblAskQuestion = "Posez moi n'importe quelle question";
            this.lblHiName = "Bonjour, Pradeep";
            this.typesToUpload = "Format de fichier pris en charge : PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.ErrorMessage = "Désolé, une erreur s'est produite. Veuillez réessayer ultérieurement."; //Gen-D Translations above 1 and below 3 too
            this.lblAskFirstQues = "Posez votre première question sur vos fichiers téléchargés pour commencer";
            this.lblSorrySomething1 = "Oops, quelque chose ne va pas. Veuillez réessayer. Si le problème persiste, veuillez vous rendre sur la ";
            this.lblSorrySomething2 = " et publier votre problème dans le canal 'Discussion'. Notre équipe s'occupera de ce problème dès que possible.";
            this.lblUserCommunity = "Communauté d'utilisateurs";
            this.lblIndexLastRefreshDate = "récupération...";
            this.lblReferences = "References:";
            break;
          case "ES":
            this.lblNameGreeting = "Hola";
            this.lblHelpGreeting = "Soy Agent Bill, su experto en materia de facturación. ¿Cómo puedo ayudarte hoy?";
            this.lblGenDIntro1 = "Gen-D es una herramienta de IA que te ayuda a trabajar más rápido. ¡Pregúntame cualquier cosa y haré lo mejor para ayudarte!";
            this.lblGenDIntro2 = "Actualización del deposito de datos: ";
            this.lblEFS_01 = "2 de enero de 2025";
            this.KB_URL = environment.DMSKBURL;
            this.lblAskQuestion = "Hazme cualquier pregunta...";
            this.lblIndexLastRefreshDate = "recuperando...";
            this.lblReferences = "References:";
            break;
          default:
            this.lblNameGreeting = "Hi";
            this.lblHelpGreeting = "I am Agent Bill, your billing subject matter expert. How can I help you today?";
            this.lblGenDIntro1 = "Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
            this.lblGenDIntro2 = "Data bucket updated: ";
            this.lblEFS_01 = "January 2nd, 2025";
            this.KB_URL = environment.DMSKBURL; //"https://genai.deloitte.ca/km";
            this.lblAskQuestion = "Ask me any question...";
            this.lblHiName = "Hi, Pradeep!";
            this.typesToUpload = "Supported file format: PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.ErrorMessage = "Sorry, something went wrong. Please try again later.";
            this.lblAskFirstQues = "Ask your first question about your uploaded files to start";
            this.lblSorrySomething1 = "Oops, something is wrong. Please try again. If the issue persists, please head to the ";
            this.lblSorrySomething2 = " and post your issue in the 'Discussion' channel. Our team will attend the issue asap.";
            this.lblUserCommunity = "User Community";
            this.lblIndexLastRefreshDate = "retrieving...";
            this.lblReferences = "References:";
            break;
        }
      });
  }
  ngOnInit() {
    this.appcomponentservice.setErrorMessage(false);
    this.appcomponentservice.setIsNewChatVisible(true);
    this.manageLocale();
    this.chatservice.getAccessState().subscribe(access => {
      if (access && !access[this.botName]) {
        this.router.navigate(['/error']);
      } else if (access) {
        const agentPath = `/${this.botName}`;
        this.recentAgentsService.addRecentPath(agentPath);
      }
    });
    this.isDefaultText = true;
    this.appcomponentservice.setIsSendButtonDisabled(false);
    this.loadingService.setLoadingState(false); // Set loading to true
    this.resetSubscription = this.resetChatService.reset$.subscribe(() => {
      this.resetVariables();
    });
    this.appcomponentservice.triggerNewChatFunction$.subscribe(() => {
      this.newChat();
    });
    this.subscriptions.push(
      this.chatTextHeightServiceService.adjustTextareaHeight$.subscribe(textarea => {
        this.adjustTextareaHeight(textarea);
      })
    );

    this.subscriptions.push(
      this.chatTextHeightServiceService.resetTextareaHeight$.subscribe(() => {
        this.resetTextareaHeight();
      })
    );
    this.sendMessageSubscription = this.appcomponentservice.triggerSendFunction$.subscribe((params: SendMessageParams) => {

      this.sendMessage(params.regenrateflag, params.userPrompt);
    });
    this.getProfile();
  }

  ngOnDestroy() {
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.loadingService.setLoadingState(false);
    }
    if (this.sendMessageSubscription) {
      this.sendMessageSubscription.unsubscribe();
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  resetVariables() {
    this.likedIndex = -1;
    this.dislikedIndex = -1;
    this.isLiked = false;
    this.isDisLiked = false;
    this.likedIndices = new Set();
    this.dislikedIndices = new Set();
    this.disabledLikeIndices = new Set<number>();
    this.disabledDisLikeIndices = new Set<number>();
  }

  async sendMessage(regenerateFlag: boolean, responsetext: any) {

    var tempPrompt = responsetext.trim();
    this.userQuery = responsetext.trim();

    if (tempPrompt == undefined || tempPrompt.trim() == "") {
      return;
    }
    else {
      this.resetDisabled = true;
      this.isStreaming = true;
      this.disableSendButton = true;
      this.isDefaultText = false;
      this.userPrompt = "";
      this.chatTextHeightServiceService.triggerResetTextareaHeight();
      // this.resetTextareaHeight();
      if (this.autoScrollEnabled) {
        this.scrollToBottom();
      }
      this.appcomponentservice.setIsSendButtonDisabled(true);
      this.loadingService.setLoadingState(true); // Set loading to true

      this.messages.next([...this.messages.value, { user: 'user', text: tempPrompt }]);
    }
    this.references = [];
    this.botResponseService.getBillingData(tempPrompt.trim()).subscribe(async (response) => {
      if (response.content === null || response.content === "") {
        this.appcomponentservice.setIsSendButtonDisabled(false);
        this.loadingService.setLoadingState(false);
        this.errorMesg = true;
        this.appcomponentservice.setErrorMessage(true);
        this.disableSendButton = false;
        this.loadingService.setstopstreaming(false);
        return;
      }
      else {
        let parsedHtml: string;
        if (typeof marked(response.content) === 'string') {
          parsedHtml = marked(response.content) as string; // Ensure it's treated as a string
        } else {
          parsedHtml = await (marked(response.content) as Promise<string>); // Handle the Promise case
        }
        // Add target="_blank" to all hyperlinks
        parsedHtml = parsedHtml.replace(
          /<a\s+href="([^"]*)"/g,
          '<a href="$1" target="_blank"'
        );

        // Remove hyperlinks where href="#"
        parsedHtml = parsedHtml.replace(
          /<a\s+href="#".*?>(.*?)<\/a>/g,
          '$1'
        );

        const parser = new DOMParser();
        const doc = parser.parseFromString(parsedHtml, 'text/html');
        const plainText = doc.body.innerHTML || ""; //doc.body.textContent || "";
        let fullMessage = plainText;

        // let fullMessage = response.content;
        if (response.references && response.references.length > 0) {
          const uniqueSources = new Set(response.references.map((reference: any) => reference.source));
          this.references = Array.from(uniqueSources).map(source => ({
            source: source,
            displayName: source // Gets the filename part
          }));
        }
        this.loadingService.setLoadingState(false); // Set loading to false           
        await this.simulateStreaming(fullMessage).then(() => {
          this.cdr.detectChanges(); // Ensure DOM updates are detected (optional)
          if (this.autoScrollEnabled) {
            this.scrollToBottom();
          }
          this.disableSendButton = false;
          this.appcomponentservice.setIsSendButtonDisabled(false);
          this.isStreaming = false;
          this.resetDisabled = false;
        })
      }
    },
      (error) => {

        this.appcomponentservice.setIsSendButtonDisabled(false);
        this.loadingService.setLoadingState(false);
        this.errorMesg = true;
        this.appcomponentservice.setErrorMessage(true);
        this.disableSendButton = false;
        this.loadingService.setstopstreaming(false);
      })
  }
  async simulateStreaming(message: string): Promise<void> {
    return new Promise((resolve) => {
      const chunkSize = 10; // Adjust chunk size as needed
      let index = 0;

      const addChunk = () => {
        if (index < message.length) {
          const isBotMessage =
            this.messages.value.length > 0 &&
            this.messages.value[this.messages.value.length - 1].user === 'bot';

          // Only include references on the last chunk
          const isLastChunk = (index + chunkSize) >= message.length;

          // Limit references to top 3 if they exist
          const limitedReferences = this.references ? this.references.slice(0, 3) : [];

          // Create the updated message
          const updatedMessage = {
            user: 'bot',
            text: isBotMessage
              ? this.messages.value[this.messages.value.length - 1].text + message.substring(index, index + chunkSize)
              : message.substring(index, index + chunkSize),
            references: isLastChunk ? limitedReferences : [] // Only include limited references in the last chunk
          };

          this.messages.next(
            isBotMessage
              ? [
                ...this.messages.value.slice(0, -1),
                updatedMessage
              ]
              : [
                ...this.messages.value,
                updatedMessage
              ]
          );

          // Call `ensurePreTagHeaders` after updating the message
          setTimeout(() => this.ensurePreTagHeaders(), 0);

          if (this.autoScrollEnabled) {
            this.scrollToBottom();
          }

          index += chunkSize;

          of(null)
            .pipe(
              delay(50), // Adjust delay as needed
              concatMap(() => of(null))
            )
            .subscribe(addChunk);
        } else {
          // Ensure limited references are added at the very end
          if (this.references?.length > 0) {
            const lastMessage = this.messages.value[this.messages.value.length - 1];
            if (lastMessage && lastMessage.user === 'bot') {
              const finalMessage = {
                ...lastMessage,
                references: this.references.slice(0, 3) // Limit to top 3 references
              };
              this.messages.next([
                ...this.messages.value.slice(0, -1),
                finalMessage
              ]);
            }
          }
          resolve();
        }
      };

      addChunk();
    });
  }

  setUserMessage(text: string) {
    this.userResponse = text;
  }

  openUserCommunityLink(): void {
    window.open(this.teamsChannelUrl, '_blank');
  }
  ensurePreTagHeaders(): void {
    if (this.autoScrollEnabled) {
      this.scrollToBottom();
    }
    console.log("ensurePreTagHeaders called");

    const preTags = this.el.nativeElement.querySelectorAll('pre');

    preTags.forEach((preTag: HTMLElement) => {
      // Only add the header if it doesn't already exist
      const existingHeader = preTag.querySelector('.preTagHeader');
      if (!existingHeader) {
        const codeTag = preTag.querySelector('code');
        if (codeTag?.className) {
          const classSplit = codeTag.className.split('-');
          const langName =
            classSplit.length > 1 &&
              classSplit[1] &&
              classSplit[1] !== 'none' &&
              classSplit[1] !== 'undefined'
              ? classSplit[1]
              : 'Plain Text';

          const headerDiv = this.render.createElement('div');
          this.render.addClass(headerDiv, 'preTagHeader');

          const leftSpan = this.render.createElement('span');
          this.render.addClass(leftSpan, 'clsLanguage');
          this.render.addClass(leftSpan, 'text-left');
          const leftText = this.render.createText(langName);
          this.render.appendChild(leftSpan, leftText);

          const rightSpan = this.render.createElement('a');
          this.render.addClass(rightSpan, 'clsCopy');
          this.render.addClass(rightSpan, 'text-right');
          const icon = this.render.createElement('i');
          this.render.addClass(icon, 'fa');
          this.render.addClass(icon, 'fa-clone');
          this.render.appendChild(rightSpan, icon);

          this.render.appendChild(headerDiv, leftSpan);
          this.render.appendChild(headerDiv, rightSpan);

          this.render.insertBefore(preTag, headerDiv, codeTag);

          this.render.listen(rightSpan, 'click', () => this.copyCode(preTag));
        }
      }
    });
  }

  copyCode(preTag: HTMLElement) {

  }

  copyToClipboard(text: string, index: number) {
    this.isCopy = true;
    this.copyIndex = index;
    const message = this.messages.value[index];
    // Remove all HTML tags
    let innerText = text.replace(/<[^>]*>/g, '');

    // Remove all occurrences of "**" and "`" (if needed)
    innerText = innerText.replace(/(\*\*|`)/g, '');

    if (message.references && message.references.length > 0) {
      // Add a line break before References section if it doesn't exist
      if (!innerText.endsWith('\n\n')) {
        innerText += '\n';
      }

      // Add "References:" header if it's not already there
      if (!innerText.includes('References:')) {
        innerText += 'References:\n';
      }

      // Add each reference on a new line
      message.references.forEach(ref => {
        innerText += `${ref.displayName}\n`;
      });
    }


    // Copy to clipboard
    navigator.clipboard.writeText(innerText).then(() => {
      console.log('Text copied to clipboard with formatting');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });

    setTimeout(() => {
      this.isCopy = false;
    }, 3000);
  }
  isAtBottom(container: HTMLElement): boolean {
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const currentScrollTop = container.scrollTop;

    // Check if the user is at the bottom, allowing for a small threshold (e.g., 15px)
    return scrollHeight - clientHeight <= currentScrollTop + 15;
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    if (!textarea) return;

    const atBottom = this.isAtBottom(this.chatContainer.nativeElement);

    // Reset height to 'auto' so that the textarea can shrink
    textarea.style.height = 'auto';

    // Calculate the new height based on the content's scrollHeight
    const maxHeight = 100; // Max height for the textarea
    const newHeight = Math.min(textarea.scrollHeight + 1.5, maxHeight); // Ensure we do not exceed the max height

    // Set the height to the new height
    textarea.style.height = `${newHeight}px`;

    if (atBottom) {
      this.scrollToBottom();
    }
  }
  updateChatContainerHeight(messageBoxHeight: number): void {
    const wrapperHeight = window.innerHeight;
    const chatContainerHeight = wrapperHeight - messageBoxHeight - 50; // Adjusting for any extra space like margins or padding
    this.render.setStyle(this.chatContainer.nativeElement, 'height', `${chatContainerHeight}px`);
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        const chatContainer = this.chatContainer.nativeElement as HTMLElement;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Scroll to bottom failed:', err);
    }

  }
  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        this.userName = profile.displayName;
        this.userId = profile.mail;
        this.displayName = profile.givenName;
      })
  }

  handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (this.userPrompt.trim() === "" || this.disableSendButton) {
        event.preventDefault(); // Prevent the default action of adding a newline
        return;
      }
      this.sendMessage(false, "");
      event.preventDefault(); // Prevent the default action of adding a newline
    }
  }

  newChat() {
    if (this.disableSendButton || this.loadingService.isLoading) {
      return;
    }
    this.resetVariables();
    this.errorMesg = false;
    this.appcomponentservice.setErrorMessage(false);
    this.isDefaultText = true;
    this.autoScrollEnabled = true;
    this.messages.next([]);
    this.guid = uuidv4();
    this.userPrompt = "";
    this.chatTextHeightServiceService.triggerResetTextareaHeight();
    this.resetTextareaHeight();
  }
  ngAfterViewInit() {
    const textarea = this.dynamicTextarea.nativeElement;
    textarea.style.height = `${this.defaultHeight}px`;
  }

  resetTextareaHeight(): void {
    const textarea = this.dynamicTextarea.nativeElement;
    if (!textarea) return;
    textarea.style.height = `${this.defaultHeight}px`;
  }

  getKBChecks() {
    this.botResponseService.getKBChecks("billing").subscribe({
      next: res => {
        if (res.result == null) {
          return;
        } var tempDate = moment.utc(res.result.last_updated, "DD-MMM-YYYY HH:mm")
          .local()
          .format("MMM D, YYYY"); // Format with month abbreviation, day, year, time, and time zone

        this.lblIndexLastRefreshDate = tempDate;
      },
      error: (error: any) => {
        console.error('Error fetching blobs:', error);
      },
      complete: () => {
        console.log('Completed fetching blobs');
      }
    });
  }
  downloadSource(source: any) {
    console.log('Download source:', source); // Debug log

    if (!source) {
      console.error('Missing required values:', {
        fileName: source
      });
      return;
    }

    // Use the source filename directly
    const fileName = source.source;
    const botAppName = "billing";
    this.botResponseService.BotDownloadDocument(
      fileName, botAppName,
    ).subscribe({
      next: (res: any) => {
        const url = window.URL.createObjectURL(res);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      },
      error: (error: any) => {
        console.error('Error downloading source:', error);
      }
    });
  }

  downloadDocument(fileName: string): void {
    // debugger;
    // const prefix = "3b451366-15bb-4a70-84ab-746ca4495f26/";
    // const fullPath = fileName.includes(prefix) ? fileName : `${prefix}${fileName}`;
    const botAppName = "billing";
    this.botResponseService.BotDownloadDocument(fileName, botAppName).subscribe({
      next: (response: Blob) => {
        // Create blob URL
        const url = window.URL.createObjectURL(response);

        // Get the original filename from the path
        const displayName = fileName.split('/').pop() || fileName;

        // Create and trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = displayName; // This ensures the file downloads with the correct name

        // Trigger download
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
        console.error('Error downloading document:', error);
        // Show user-friendly error message
        this.errorMesg = true;
        this.loadingService.setLoadingState(false);
      }
    });
  }

  openFilePreview(source: any): void {

    const fileName = source.source.split('/').pop() || source;

    this.modalService.open(FilePreviewPopupComponent, {
      fileName: fileName,
      fileSource: source.source,
      botAppName: "billing"
    }).onClosed().subscribe(() => {
      // Handle any cleanup if needed
    });
  }
}
interface SendMessageParams {
  regenrateflag: boolean;
  userPrompt: string;
}