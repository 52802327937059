import { Component, EventEmitter, Input, OnInit, forwardRef, HostListener, Output, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedText } from 'src/app/model/agent.model';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';

export interface DropdownOption {
  label: LocalizedText;
  value: any;
  disabled?: boolean;
}
 
@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string = 'Select an option';
  @Input() disabled: boolean = false;
  @Input() options: DropdownOption[] = [];
  language: string = 'en';
  @Output() valueChange: EventEmitter<any> = new EventEmitter(); 
  private destroy = new Subject<void>(); 
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;
  private readonly DROPDOWN_MAX_HEIGHT = 200;
  private readonly VIEWPORT_MARGIN = 16;
  private openUpwards = false;
  readonly uniqueId: string = `dropdown-${Math.random().toString(36).substr(2, 9)}`;


  private _value: any = null;
  isOpen: boolean = false;
  selectedOption: DropdownOption | null = null;
 
  // Callbacks for the ControlValueAccessor
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
 
  ngOnInit() {
    // Initialize selectedOption based on the existing value
    this.selectedOption = this.options.find(opt => opt.value === this._value) || null;
    this.manageLocale();
  }

  constructor(
    private i18n: I18nService,
    private renderer: Renderer2,
    private elementRef: ElementRef  // Add ElementRef injection
) {}

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.language = 'fr'
            break;
            case "ES":
              this.language = 'es'
              break;
          default:
            this.language = 'en'
            break;
        }
      });
  }   

  writeValue(value: any): void {
    this._value = value;
    this.selectedOption = this.options.find(opt => opt.value === this._value) || null;
  }
 
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
 
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
 

// Add overloaded method signatures
selectOption(option: DropdownOption): void;
selectOption(event: MouseEvent | KeyboardEvent, option: DropdownOption): void;
selectOption(eventOrOption: MouseEvent | KeyboardEvent | DropdownOption, option?: DropdownOption): void {
    if (this.disabled) return;

    // Determine if first parameter is event or option
    const isEvent = eventOrOption instanceof Event;
    const selectedOption = isEvent ? option! : eventOrOption as DropdownOption;
    
    if (selectedOption.disabled) {
        return;
    }
    
    // Handle selection and close dropdown
    this.selectedOption = selectedOption;
    this._value = selectedOption.value;
    this.isOpen = false; // Close dropdown
    
    // Trigger changes
    this.onChange(this._value);
    this.valueChange.emit(this._value);
    this.onTouched();
    
    // Handle event if present
    if (isEvent) {
        const event = eventOrOption as Event;
        event.stopPropagation();
        event.preventDefault();
    }
    
    // Set focus back to button after selection
    requestAnimationFrame(() => {
        const button = this.dropdownContainer.nativeElement.querySelector('.dropdown-button');
        if (button) {
            button.focus();
        }
    });
}

@HostListener('document:click', ['$event'])
onDocumentClick(event: MouseEvent) {
    // Skip if the click is inside the dropdown or if dropdown is not open
    if (!this.isOpen || this.elementRef.nativeElement.contains(event.target)) {
        return;
    }
    this.isOpen = false;
}

toggleDropdown(event: MouseEvent): void {
    if (!this.disabled) {
        // Always stop event propagation to prevent modal closing
        event.stopPropagation();
        event.preventDefault();
        
        if (!this.isOpen) {
            // Close other dropdowns using custom event to avoid closing modal
            const closeEvent = new CustomEvent('closeDropdowns', {
                bubbles: true,
                cancelable: true
            });
            this.elementRef.nativeElement.dispatchEvent(closeEvent);
            
            // Assess position before opening
            this.assessDropdownPosition();
        }
        
        // Toggle state last
        this.isOpen = !this.isOpen;
    }
}

// Add new event listener for custom close event
@HostListener('document:closeDropdowns', ['$event'])
onCloseDropdowns(event: Event) {
    // Don't close if event originated from this dropdown
    if (!this.elementRef.nativeElement.contains(event.target)) {
        this.isOpen = false;
    }
}

private assessDropdownPosition(): void {
    const button = this.dropdownContainer.nativeElement.querySelector('.dropdown-button');
    
    if (button) {
        const rect = button.getBoundingClientRect();
        const modalBody = this.elementRef.nativeElement.closest('.dds-modal__body');
        const containerRect = modalBody ? 
            modalBody.getBoundingClientRect() : 
            document.body.getBoundingClientRect();
        
        const spaceBelow = containerRect.bottom - rect.bottom - this.VIEWPORT_MARGIN;
        const spaceAbove = rect.top - containerRect.top - this.VIEWPORT_MARGIN;
        const contentHeight = Math.min(
            this.options.length * 36,
            this.DROPDOWN_MAX_HEIGHT
        );
        
        this.openUpwards = spaceBelow < contentHeight && spaceAbove > spaceBelow;
    }
}

@HostListener('keydown', ['$event'])
onKeyDown(event: KeyboardEvent): void {
  if (this.disabled) return;

  switch (event.key) {
    case 'Enter':
      event.preventDefault();
      event.stopPropagation(); // Add this to prevent event bubbling
      
      if (this.isOpen) {
        if (this.selectedOption) {
          this.selectOption(event, this.selectedOption); // Pass the event to selectOption
          event.stopImmediatePropagation(); // Stop other handlers
        }
      } else {
        this.isOpen = true;
        this.assessDropdownPosition();
      }
      return; // Add early return

    case ' ': // Space key
      event.preventDefault();
      if (!this.isOpen) {
        this.isOpen = true;
        this.assessDropdownPosition();
      }
      break;

    case 'Escape':
      if (this.isOpen) {
        event.preventDefault();
        this.isOpen = false;
      }
      break;

    case 'ArrowDown':
    case 'ArrowUp':
      event.preventDefault();
      if (!this.isOpen) {
        this.isOpen = true;
      } else {
        this.navigateOptions(event.key === 'ArrowDown' ? -1 : 1);
      }
      break;

    case 'Tab':
      if (this.isOpen) {
        this.isOpen = false;
      }
      break;
  }
}

private navigateOptions(delta: number): void {
  const enabledOptions = this.options.filter(opt => !opt.disabled);
  const currentIndex = enabledOptions.findIndex(opt => opt.value === this.selectedOption?.value);
  let newIndex = currentIndex + delta;

  if (newIndex < 0) newIndex = enabledOptions.length - 1;
  if (newIndex >= enabledOptions.length) newIndex = 0;

  this.selectedOption = enabledOptions[newIndex];
  this._value = this.selectedOption.value;
  this.onChange(this._value);
  this.valueChange.emit(this._value);
}

}