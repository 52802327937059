<div #dropdownContainer class="dropdown-container" [class.is-open]="isOpen">
    <button
      type="button"
      class="dropdown-button"
      [attr.aria-expanded]="isOpen"
      [attr.aria-haspopup]="true"
      [attr.aria-controls]="'dropdown-list-' + uniqueId"
      [attr.aria-labelledby]="'dropdown-label-' + uniqueId"
      [attr.aria-disabled]="disabled"
      (click)="toggleDropdown($event)"
      (keydown)="onKeyDown($event)"
      [tabindex]="disabled ? -1 : 0">
      <span id="dropdown-label-{{uniqueId}}">
        {{ selectedOption?.label[language] || placeholder }}
      </span>
      <img 
        src="../../../assets/images/dropdown-down.svg" 
        class="icon" 
        alt=""
        aria-hidden="true" />
    </button>
  
    @if (isOpen) {
      <ul
        class="dropdown-options"
        [class.dropdown-up]="openUpwards"
        [class.dropdown-down]="!openUpwards"
        role="listbox"
        [attr.aria-activedescendant]="selectedOption ? 'option-' + selectedOption.value : null"
        id="dropdown-list-{{uniqueId}}">
        @for (option of options; track option.value) {
          <li
            class="dropdown-option"
            role="option"
            [id]="'option-' + option.value"
            [class.disabled]="option.disabled"
            [class.selected]="option === selectedOption"
            [attr.aria-selected]="option === selectedOption"
            [attr.aria-disabled]="option.disabled"
            (click)="selectOption($event, option)">
            {{ option.label[language] }}
          </li>
        }
      </ul>
    }
  </div>